import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import TmReactTable from '../../utilComponents/tmReactTable/tmReactTable';
import { Modal } from 'react-bootstrap';
import TmExportExcel from '../../utilComponents/tmExportExcel/tmExportExcel';
import FullScreen from 'react-request-fullscreen';
import {callStatus,priorities} from '../../../util/constants'
import DispatchReview from '../../../containers/dispatcher/dispatchReview'
import moment from 'moment'
import StatusSearch from './statusSearch'
import {isManagement,setDashboardFilterByType,dashboardFilterByType} from '../../../util/customMethods';
const actionObj={
  'list1':['Unassigned','OnHold','Requested'],
  'list2':['Assigned','Accepted','Enroute','Arrived','Hooked'],
  'list3':['Dropped'],
  'list4':['Cleared'],
}
class CallVisibility extends Component {
 state = {
        byStatus: callStatus, 
        byPriority: priorities,
        filterData:this.props.data||[],
        showReasonModal:false,
        reasonNote:'Canceled',
        showClearModal:false,
        selectedInvoiceObj:{},
        isFullScreen: false,
        dispatchReview:false,
        dispatchReInvoice:''
    }
  componentWillReceiveProps=(nextPros)=>{
    let ByCallStatus = dashboardFilterByType('byStatus');
    let ByCallpriority = dashboardFilterByType('byPriority');
    this.setState({
      byStatus:ByCallStatus,byPriority:ByCallpriority,
      filterData:this.filterList(nextPros.data||[])
    })
  }
  handleClose=(isClear=false)=>{
    if(isClear){
      this.setState({showClearModal:false,selectedInvoiceObj:{}})
    }else{
    this.setState({showReasonModal:false,selectedInvoiceObj:{}})
    }
  }
  handleOk=(isClear=false)=>{
    if(isClear){  
      this.setState({showClearModal:false},()=>{
        let req={status:'Cleared',
          invoiceId:this.state.selectedInvoiceObj.invoiceId,
          note:''
        } 
        this.props.onCallActionBtnClick(req)
      })
    }else{  
      this.setState({showReasonModal:false},()=>{
        let req={
          status:'Cancel',
          invoiceId:this.state.selectedInvoiceObj.invoiceId,
          note:this.state.reasonNote
        }
        this.props.onCallActionBtnClick(req)
      })
    }
  }
  filterList=(allList)=>{
    const {byStatus, byPriority}=this.state;
    let _byStatus=[];
    let _byPriorities=[];
    let ByCallStatus = dashboardFilterByType('byStatus');
    Object.keys(byStatus).forEach(item=>{
      if(byStatus[item].selected){
        _byStatus.push(item.toLowerCase())
      }
    })
    Object.keys(byPriority).forEach(item=>{
      if(byPriority[item].selected){
        _byPriorities.push( parseInt(item))
      }
    })
    if(_byStatus.length===0 && _byPriorities.length===0 )
    return allList;
    else{
      let filterData=[];
      filterData=allList.filter(item=>{
        return ( _byStatus.length>0?_byStatus.includes((item.callStatus).toLowerCase()):true)
      });
      let priorityFilter=filterData.filter(item=>{
        return ( _byPriorities.length>0?_byPriorities.includes(item.priority):true)
      });
      return priorityFilter;
    }
  }
  onActionButtonClick=(row,action)=>{
    switch(action){
      case "Cleared":
        if(row.isPoRequired===1 && (row.poNo||'')===''){
          this.setState({showClearModal:true,selectedInvoiceObj:row})
        }else{
          this.props.onCallActionBtnClick({status:action, invoiceId:row.invoiceId, note:''})
        }    
        break;
      case "Cancel":
        this.setState({showReasonModal:true,selectedInvoiceObj:row});
        break;
      case "Assign":
      case "Reassign":
        this.props.onCallActionBtnClick({status:action, invoiceId:row.invoiceId, note:''})
        break;
      default:
        console.log();
        break;
    }
  }
  getColorCode(priority) {
    var colorClass = "";
    switch (priority) {
      case 1:
        colorClass = "priority-red";
        break;
      case 2:
        colorClass = "priority-purple";
        break;
      case 3:
        colorClass = "priority-orange";
        break;
      case 4:
        colorClass = "priority-yellow";
        break;
      case 5:
        colorClass = "priority-green";
        break;
      case 6:
        colorClass = "priority-blue";
        break;
      case 7:
        colorClass = "priority-brown";
        break;
      case 8:
        colorClass = "priority-light-grey";
        break;
      case 9:
        colorClass = "priority-black";
        break;
    }
    return colorClass;
  }
  buildDataFormatForExport = () => {
    return (
      [{
        columns: [{ title: 'Invoice #' }, { title: 'Call Status' }, { title: 'Requested By' }, { title: 'Rateplan' }, { title: 'Reason' }, { title: 'Pickup Address' }
        , { title: 'Dropoff Address' }, { title: 'Driver' }, { title: 'Truck' }, { title: 'Tow' }, { title: 'Elapsed Time' }, { title: 'Elapsed ETA' }
        , { title: 'Elapsed ETD' }],
        data: this.props.data.map(item => {
          return [{ value: item.invoiceId || '' }, { value: item.callStatus || '' }, { value: item.accountName || '' }, { value: item.ratePlanName || '' }, { value: item.reason || '' }, { value: item.pickupAddress || '' }
          , { value: item.dropoffAddress || '' }, { value: item.userId || '' }, { value: item.truckNo || '' }, { value: item.towType || '' }, { value: item.elapsedTime || '' }, { value: item.etaTime || '' }
          , { value: item.etdTime || '' }]
        })
      }]
    )
  }
  requestOrExitFullScreenByElement (val) {
    var css = `.ReactTable-CallVisibility-Fullscreen .rt-table .rt-tbody {
      animation: autoscroll ${val > 11 ? val:0}s linear infinite;
      -webkit-animation: autoscroll ${val > 11 ? val:0}s linear infinite;
      -moz-animation: autoscroll ${val > 11 ? val:0}s linear infinite;
      -ms-animation: autoscroll ${val > 11 ? val:0}s linear infinite;
      -o-animation: autoscroll ${val > 11 ? val:0}s linear infinite;
  }`,
    head = document.head || document.getElementsByTagName('head')[0],
    style = document.createElement('style');

    head.appendChild(style);

    style.type = 'text/css';
    if (style.styleSheet){
      // This is required for IE8 and below.
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }
        this.elFullScreenRef.fullScreen(this.elRef)
      }
  onFullScreenChange (isFullScreen) {
    this.setState({
      isFullScreen
    })
  }
  
  render() {
    const { data } = this.props;
    const { filterData, isFullScreen } = this.state
    const textCmp = (isManagement()?"Driver | Truck | Company":"Driver | Truck");
    return (
      <Fragment>
        <div className="row">
        <div className="col-md-12 mb-5">
        <StatusSearch key={'callStatus'} byType={'callStatus'} id={'callStatus'} label={'Status:'} 
        searchObject={this.state.byStatus} 
        onChange={(newObj)=>{
          let _search=this.state.byStatus;
          _search=newObj;
          this.setState({byStatus:_search},()=>{
            this.setState({filterData:this.filterList(this.props.data)})
          })
          setDashboardFilterByType("byStatus",newObj);
        }} />
        </div>
        </div>
        <div className="row">
          <div className="col-md-10 mb-5">
            <StatusSearch key={'pri'} id={'pri'} label={'Priority:'} 
            searchObject={this.state.byPriority} 
            onChange={(newObj)=>{
              let _search=this.state.byPriority;
              _search=newObj;
              this.setState({byPriority:_search},()=>{
                this.setState({filterData:this.filterList(this.props.data)})
              })
              setDashboardFilterByType("byPriority",newObj);
          }} />
          </div>
          <div className="col-md-2 mb-5">
          <button className="btn btn-sm btn-primary" onClick={()=>this.requestOrExitFullScreenByElement(filterData.length)}><i className="fa fa-arrows-alt" aria-hidden="true"></i></button>&nbsp;&nbsp;&nbsp;
            <TmExportExcel
                    name={"Call list"}
                    filename={`${"callList_"}${moment().format('MMDDYYhhmm')}`} 
                    fileExtension={'xlsx'}
                    element={<button className="btn btn-warning btn-sm"><i className="fa fa-file-excel-o"></i></button>}
                    dataSet={this.buildDataFormatForExport()}
                  />
          </div>
        </div>
        <FullScreen ref={ref => { this.elFullScreenRef = ref }} onFullScreenChange={this.onFullScreenChange.bind(this)}>
          <div ref={ref => { this.elRef = ref }} style={{backgroundColor:'#fff'}}>
            {!isFullScreen ?
            null:
            <TmReactTable
            filterable={false}
            sortable={false}
            showPagination={false}
            resizable={false}
            style={{ fontSize: '13px', backgroundColor:'#fff' }}
            defaultPageSize={filterData.length}
            minRows={0}
            data={filterData}
            className="-striped -highlight ReactTable-CallVisibility-Fullscreen"
            columns={[{
              Header: 'Invoice# | Status',
              accessor: 'invoiceId',
              style: { textAlign: 'center', display: 'inline-grid', whiteSpace: 'normal'  },
              width: 200,
              Cell: row => (
                <div className={`text-center ${this.getColorCode(row.original.priority)}`}>
                   { (row.original.isArsCall==1) && ((row.original.ratePlanName||'').trim().length===0? <span className="badge badge-success pull-right" style={{fontSize:'9px'}}>ARS (NEW)</span>:<span className="badge badge-primary pull-right" style={{fontSize:'9px'}}>ARS (VERIFIED)</span>)}
                    <Link onClick={()=>{this.props.routeInv(row.original.invoiceId)}} className="color-priority" >
                      {row.original.displayInvoiceId}
                    </Link>
                    <Link href={`serviceCallInfo/${row.original.invoiceId}`}>
                      <span className={(row.original.callStatus || '').toLowerCase()}>
                        {row.original.callStatus}
                      </span>
                    </Link>
                    {(((row.original.autoAssignStatus || '').toLowerCase() === '') && (!row.original.disableAutoAssign)) ? <div style={{fontSize:'9px', color:'#007bff'}}>Auto Assign In-Progress</div>:null}
                </div>
              )
            },
            {
                Header: 'Requested By',
                accessor: 'accountName',
                style: { display: 'inline-grid', whiteSpace: 'normal' },
                Cell: row => ( 
                  <a href="javascript:void(0)">
                    {row.original.accountName}
                  </a>
                )
            },
            {
                Header: 'Reason | Rate Plan',
                id: 'reason',
                style: { display: 'inline-grid', whiteSpace: 'normal' },
                Cell:row=>(
                  <><div><span>{row.original.reason}</span></div>
                    <div><span>{row.original.ratePlanName}</span></div></>          
                )
            },
            {
                Header: 'Locations',
                id: 'location',
                width: 400,
                style: { display: 'inline-grid' },
                Cell: row => (
                  <span>
                    <span><strong style={{color: '#2a5c8a', fontWeight: '700'}}>From- </strong>
                    <span style={{color:((row.original.pickupLatlang||'')==='')?'#CB0000':''}}>{row.original.pickupAddress}</span><br></br>
                    <strong style={{color: '#2a5c8a', fontWeight: '700'}}>To- </strong>
                    <span style={{color:((row.original.dropLatlng||'')==='')?'#CB0000':''}}>{row.original.dropoffAddress}</span>
                    </span>
                  </span>
                )
            },
            {
                Header: textCmp,
                accessor: 'dri',
                width: 250,
                style: { display: 'inline-grid' },
                Cell: row => (
                  <span>
                    <span><strong style={{color: '#2a5c8a', fontWeight: '700'}}>Driver- </strong>
                    <span>{row.original.userId}</span><br></br>
                    <strong style={{color: '#2a5c8a', fontWeight: '700'}}>Truck- </strong>
                    <span>{row.original.truckNo}</span><br></br>
                    {isManagement()?<><strong style={{color: '#2a5c8a', fontWeight: '700'}}>Company- </strong>
                    <span>{row.original.companyName || ''}</span></>:null}
                    </span>
                  </span>
                )
            },
            {
                Header: 'Elapsed | ETA | ETD',
                accessor: 'ela',
                style: { textAlign: 'center', display: 'inline-grid', whiteSpace: 'normal' },
                filterable: false,
                Cell: row => (
                  <a href={`serviceCallInfo/${row.original.invoiceId}`} style={{ color: 'black'}}>
                    <div className={''}>
                    <span className="time-color">
                      {row.original.elapsedTime}</span>
                    <em><strong>{row.original.etaTime} | {row.original.etdTime}</strong></em></div>
                  </a>
              )
            }
          ]}
          />
            }
          </div>
        </FullScreen>
        <div className="row">
          <div className="col-md-12 col-sm-12 mb-10">
            <TmReactTable
              filterable
              sortable
              resizable
              defaultPageSize={25}
              data={this.state.filterData}
              className="ReactTable-CallVisibility"
              columns={[{
                Header: 'Invoice# | Status',
                accessor: 'invoiceId',
                width: 120,
                Cell: row => (
                  <div className={`text-center ${this.getColorCode(row.original.priority)}`}>
                    { (row.original.isArsCall==1) && ((row.original.ratePlanName||'').trim().length===0? <span className="badge badge-success pull-right" style={{fontSize:'9px'}}>ARS (NEW)</span>:<span className="badge badge-primary pull-right" style={{fontSize:'9px'}}>ARS (VERIFIED)</span>)}
                    {row.original.isPaymentConfirmed?<span className="badge badge-warning pull-right blinking-row" style={{ fontSize: '9px', marginTop:'-5px' }}>$ Confirm Price</span>:null}
                      <a onClick={()=>{
                        let reviewRequired = (row.original.isArsCall==1&& (row.original.ratePlanName||'').trim().length===0);
                        if(reviewRequired){
                          this.setState({dispatchReview:true,dispatchReInvoice:row.original.invoiceId})
                        }else{
                          this.props.routeInv(row.original.invoiceId)
                        }
                        }} className="color-priority" >
                        {row.original.displayInvoiceId}
                      </a>
                      <a onClick={()=>{
                        let reviewRequired = (row.original.isArsCall==1&& (row.original.ratePlanName||'').trim().length===0);
                        if(reviewRequired){
                          this.setState({dispatchReview:true,dispatchReInvoice:row.original.invoiceId})
                        }else{
                          this.props.routeInv(row.original.invoiceId)
                        }
                       }
                        }>
                        <span className={(row.original.callStatus || '').toLowerCase()}>
                          {row.original.callStatus}
                        </span>
                      </a>
                      {(((row.original.autoAssignStatus || '').toLowerCase() === '') && (!row.original.disableAutoAssign))? <div style={{fontSize:'9px', color:'#007bff'}}>Auto Assign In-Progress</div>:null}
                  </div>
                ),
                filterMethod: (filter, row) =>
                (row._original.invoiceId).includes(filter.value) ||
                  ((row._original.callStatus).toString()).toLowerCase().startsWith(filter.value.toLowerCase())
              },
              {
                  Header: 'Requested By',
                  accessor: 'accountName',
                  width: 120,
                  style: { display: 'inline-grid', whiteSpace: 'normal' }
              },
              {
                  Header: 'Reason | Rate Plan',
                  id: 'reason',
                  style: { display: 'inline-grid', whiteSpace: 'normal' },
                  Cell:row=>(
                    <><div><span>{row.original.reason}</span></div>
                      <div><span>{row.original.ratePlanName}</span></div></>          
                  ),
                  filterMethod: (filter, row) =>
                              ((row._original.reason || '').toString()).toLowerCase().includes(filter.value.toLowerCase()) ||
                              ((row._original.ratePlanName || '').toString()).toLowerCase().includes(filter.value.toLowerCase())
              },
              {
                  Header: 'Locations',
                  id: 'location',
                  Cell: row => (
                    <span>
                      <span><strong style={{color: '#2a5c8a', fontWeight: '700'}}>From- </strong>
                      <span style={{color:((row.original.pickupLatlang||'')==='')?'#CB0000':''}}>{row.original.pickupAddress}</span><br></br>
                      <strong style={{color: '#2a5c8a', fontWeight: '700'}}>To- </strong>
                      <span style={{color:((row.original.dropLatlng||'')==='')?'#CB0000':''}}>{row.original.dropoffAddress}</span>
                      </span>
                    </span>
                  ),
                  filterMethod: (filter, row) =>
                            ((row._original.pickupAddress || '').toString()).toLowerCase().includes(filter.value.toLowerCase()) ||
                              ((row._original.dropoffAddress || '').toString()).toLowerCase().includes(filter.value.toLowerCase())
              },
              {
                  Header: textCmp,
                  accessor: 'dri',
                  Cell: row => (
                    <span>
                      <span><strong style={{color: '#2a5c8a', fontWeight: '700'}}>Driver- </strong>
                      <span>{row.original.userId}</span><br></br>
                      <strong style={{color: '#2a5c8a', fontWeight: '700'}}>Truck- </strong>
                      <span>{row.original.truckNo}</span><br></br>
                      {isManagement()?<><strong style={{color: '#2a5c8a', fontWeight: '700'}}>Company- </strong>
                      <span>{row.original.companyName || ''}</span></>:null}
                      </span>
                    </span>
                  ),
                  filterMethod: (filter, row) =>
                            (row._original.userId || '').toLowerCase().includes(filter.value.toLowerCase()) ||
                              ((row._original.truckNo || '').toString()).toLowerCase().includes(filter.value.toLowerCase())||
                              ((row._original.companyName || '').toString()).toLowerCase().includes(filter.value.toLowerCase())

              },
              {
                  Header: 'Elapsed | ETA | ETD',
                  accessor: 'ela',
                  style: { textAlign: 'center', display: 'inline-grid' },
                  filterable: false,
                  Cell: row => (
                    <a href={`serviceCallInfo/${row.original.invoiceId}`} style={{ color: 'black'}}>
                      <div className={''}>
                      <span className="time-color">
                        {row.original.elapsedTime}</span>
                      <em><strong>{row.original.etaTime} | {row.original.etdTime}</strong></em></div>
                    </a>
                )
              },
              {
                  Header: 'Actions',
                  accessor: 'callStatus',
                  style: { textAlign: 'center' },
                  filterable: false,
                  Cell: (row) => {
                   const status= row.original.callStatus;
                   const priceDiv = <div className="btn-group"><Link
                   //to={`serviceCallInfo/${row.original.invoiceId}&tabKey=TB_BILLING`}
                   to={`serviceCallInfo/${row.original.invoiceId}`}
                   className="btn btn-sm btn-danger blinking-row"
                  > $ Price</Link>
                   </div>;
                   const actionDiv = (actionObj.list3.includes(status)?null:
                   actionObj.list4.includes(status)?<button className="btn btn-sm btn-success" hidden={!this.props.canClear} onClick={(e)=>{
                     this.onActionButtonClick(row.original,'Cleared');
                   }}>Clear</button>:
                   actionObj.list2.includes(status)?<div className="btn-group">
                   <button className="btn btn-sm btn-default" hidden={!this.props.canAssign} onClick={(e)=>{
                     this.onActionButtonClick(row.original,'Reassign');
                   }}>Re/Unassign</button>
                   <button className="btn btn-sm btn-warning" hidden={!this.props.canCancel} onClick={(e)=>{
                     this.onActionButtonClick(row.original,'Cancel');
                   }}>Cancel</button></div>:
                   actionObj.list1.includes(status)?<div className="btn-group">
                   <button className="btn btn-sm btn-success" hidden={!this.props.canAssign} onClick={(e)=>{
                     this.onActionButtonClick(row.original,'Assign');
                   }}>Assign</button>
                   <button className="btn btn-sm btn-warning" hidden={!this.props.canCancel} onClick={(e)=>{
                     this.onActionButtonClick(row.original,'Cancel');
                   }}>Cancel</button></div>:null
                   )

                   return row.original.isPaymentConfirmed?(
                    <div>
                    {priceDiv}
                    {actionDiv}
                    </div>
                  ):<div>{actionDiv}</div>
                  }
              }
            ]}
            />
          </div></div>

        <Modal show={this.state.showReasonModal} onHide={()=>{this.handleClose()}}>
          <Modal.Header closeButton>
            <Modal.Title>Reason</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label className="form-control-label">Enter the reason to cancel</label>
                  <input name="reasonNote" type="text" className="form-control"  placeholder=""
                      value={this.state.reasonNote} onChange={(e)=>{
                        this.setState({reasonNote:e.target.value});    
                      }} />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm btn-danger" onClick={()=>{this.handleClose()}}>
              Close
            </button>
            <button className="btn btn-sm btn-success" onClick={()=>{this.handleOk()}}>
              Submit
            </button>
          </Modal.Footer>
      </Modal>
      <Modal show={this.state.showClearModal} onHide={()=>{this.handleClose(true)}}>
          <Modal.Header closeButton>
            <Modal.Title>PO Required</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label className="form-control-label">P.O. Is required for this account. Press CANCEL to go back and enter P.O. or CLEAR to proceed to clear without P.O.</label>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-sm btn-danger" onClick={()=>{this.handleClose(true)}}>
              Cancel
            </button>
            <button className="btn btn-sm btn-success" onClick={()=>{this.handleOk(true)}}>
              Clear
            </button>
          </Modal.Footer>
      </Modal>
      <Modal show={this.state.dispatchReview} onHide={()=>{this.setState({dispatchReview:false})}} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>DISPATCH REVIEW</Modal.Title>
          </Modal.Header>
          <div className="modal-body">
          <div className="form-group row account-info-popup">
              <DispatchReview dispatchReInvoice={this.state.dispatchReInvoice} dispatchReview={this.state.dispatchReview} 
              onClose = {()=>{this.setState({dispatchReview:false})}}/>
              </div>
            </div>
      </Modal>
      </Fragment>
    );
  }
}

export default CallVisibility;
