import React from 'react';
import moment from 'moment';
import Cards from 'react-credit-cards';
import CountdownTimer from 'react-component-countdown-timer';
import 'react-credit-cards/es/styles-compiled.css';
import "react-component-countdown-timer/lib/styles.css";
//import {getAxiosInstance}from '../../../util/service';
//import {LAUNCH_LOGIN_URL, moduleTypes} from '../../../util/constants'
export default class PaymentProcess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      number: '',
      loading:false,
      authCd: '',
      transStatus: '',
      name: '',
      transDate: '',
      expiry: '',
      cardType: '',
      cvc: '',
      cardNum: '',
      transId: '',
      issuer: '',
      focused: '',
      formData: null,
      clientPaymentDetails: {},
      localPinSwipe: '',
      localPinKeyInNumber: '',
      gatewayID: '',
      chanID: '',
      cardHidden: false,
      billinghidden: false,
      exDatehidden: false,
      taxhidden: false,
      namehidden: false,
      cvchidden: false,
      codehidden: false,
      //invoiceId: '',
      invoiceId: '',
      paymentAmt: props.paymentAmt || 0.0,
      //amt: 0.00,
      cardNo: '',
      xpDate: '',
      slsTax: '',
      name: '',
      cvc2: '',
      customerCode: '',
      companyName: '',
      name1: '',
      name2: '',
      address1: '',
      address2: '',
      city: '',
      stateName: '',
      postalCode: '',
      countaryName: '',
      PhoneNo: '',
      email: '',
      partialAuthIndicator: 0,
      cvv2cvc2: '',
      vavs_zip: '',
      avs_address: '',
      cvv2cvc2Indicator: 1,
      cancelledResponsedata: {},
      paymentInfo: {}

    };
    // this.getPaymentClientDetails = this.getPaymentClientDetails.bind(this);
    // this.openPaymentGateway = this.openPaymentGateway.bind(this);
    // this.startPymtTransaction = this.startPymtTransaction.bind(this);
    // this.getPaymentTransactionStatus = this.getPaymentTransactionStatus.bind(this);
    // this.closePaymentGateway = this.closePaymentGateway.bind(this);
    this.onSwipeClick = this.onSwipeClick.bind(this);
    this.save = this.save.bind(this);
  }

  componentWillMount() {
    //this.getPaymentClientDetails();
    window.removeEventListener("message", this.handleMessage);

  }
  doSomethingBeforeUnload = () => {
   this.closeWin()
}

// Setup the `beforeunload` event listener
setupBeforeUnloadListener = () => {
    window.addEventListener("beforeunload", (ev) => {
        ev.preventDefault();
        return this.doSomethingBeforeUnload();
    });
};

componentDidMount() {
  window.addEventListener("message", this.handleMessage);
    // Activate the event listener
    this.setupBeforeUnloadListener();
}

handleMessage = (event) => {
  // In production, always validate event.origin for security
  console.log("Received userData:", event.data);
  this.setState({ paymentInfo: event.data });
};
  
  save() {
    let myWindow = window.open("", "_top");
  }


  handleCallback = ({ issuer }, isValid) => {
    if (isValid) {
      this.setState({ issuer });
    }
  };

  handleInputFocus = ({ target }) => {
    this.setState({
      focused: target.name,
    });
  };

//   handleInputChange = ({ target }) => {
//     if (target.name === 'number') {
//       target.value = formatCreditCardNumber(target.value);
//     } else if (target.name === 'expiry') {
//       target.value = formatExpirationDate(target.value);
//     } else if (target.name === 'cvc') {
//       target.value = formatCVC(target.value);
//     }

//     this.setState({ [target.name]: target.value });
//   };

  handleSubmit = e => {
    e.preventDefault();
    const { issuer } = this.state;
    const formData = [...e.target.elements]
      .filter(d => d.name)
      .reduce((acc, d) => {
        acc[d.name] = d.value;
        return acc;
      }, {});

    this.setState({ formData });
    this.form.reset();
  };
  /*PAYMENT GATWAY IF PAYMENT TYPE IS CREADIT TYPE */
//   getPaymentClientDetails() {
//     let paymentGatWayDetails = {};
//     let headers = {
//       'Accept': 'application/json',
//       'Content-Type': 'application/json'
//     }
//     postWithHeaders('/newservicecall/get/paymentClient?companyId=' + companyId(), localStorage.getItem("paymentDataforCredit"), headers)
//       .then((response) => {
//         if (!response.ok) {
//           throw Error(response.statusText);
//         }
//         console.log('response', response);
//         return response.json();
//       }).then(data => {
//         let locationForKeyNo = "";
//         let locationForSwipe = "";
//         if (data.paymentLocation == 'A-LOT'){
//         locationForKeyNo = data.pinKeyInEntryA_Lot;
//         locationForSwipe = data.pinSwipeA_Lot;
//         }
//         else if (data.paymentLocation == 'ACCOUNTING'){
//           locationForKeyNo = data.pinKeyInEntryAccounting;
//           locationForSwipe = data.pinSwipeAccounting;
//           }
//         else if (data.paymentLocation == 'B-LOT'){
//           locationForKeyNo = data.pinKeyInEntryB_Lot;
//           locationForSwipe = data.pinSwipeB_Lot;
//           }
//         else if (data.paymentLocation == 'DISPATCH'){
//           locationForKeyNo = data.pinKeyInEntryDispatch;
//           locationForSwipe = data.pinSwipeDispatch;
//           }
//         else if (data.paymentLocation == 'E-LOT'){
//           locationForKeyNo = data.pinKeyInEntryE_Lot;
//           locationForSwipe = data.pinSwipeE_Lot;
//           }
//         else if (data.paymentLocation == 'F-LOT'){
//           locationForKeyNo = data.pinKeyInEntryF_Lot;
//           locationForSwipe = data.pinSwipeF_Lot;
//           }
//         else if (data.paymentLocation == 'G-LOT'){
//           locationForKeyNo = data.pinKeyInEntryG_Lot;
//           locationForSwipe = data.pinSwipeG_Lot;
//           }
//         this.setState({
//           clientPaymentDetails: data,
//           localPinSwipe: locationForSwipe,
//           localPinKeyInNumber:locationForKeyNo,
//           invoiceId: data.invoiceId,
//           amt: parseFloat(data.payAmount).toFixed(2),
//           payment_location:data.paymentLocation
//         })
//       })
//       .catch(
//         (error) => {
//           console.log('PAYMENT GATWAY DETAILS', error)
//         }
//       )
//     return paymentGatWayDetails;
//   }
  /*============================================PAYMENT GATEWAY METHOD SECTION======================================== */
  /*METHOD TO OPEN GATEWAY*/
//   openPaymentGateway() {
//     let gatewayResult = new Object();
//     let headers = {
//       'Accept': 'application/json',
//       'Content-Type': 'application/json'
//     }
//     this.setState({loading:true})
//     let jsonToOpenGateWay = JSON.stringify({
//       method: this.state.clientPaymentDetails.localOpenPymtGatewayMethod,
//       requestId: this.state.clientPaymentDetails.localRequestID,
//       targetType: this.state.clientPaymentDetails.localTargetType,
//       version: this.state.clientPaymentDetails.localVersion,
//       parameters: {
//         app: this.state.clientPaymentDetails.localApp,
//         email: this.state.clientPaymentDetails.localEmail,
//         pin: this.state.localPinSwipe,
//         userId: this.state.clientPaymentDetails.userId,
//         handleDigitalSignature: true,
//         paymentGatewayEnvironment: this.state.clientPaymentDetails.localEnvironmentType,
//         merchantId: this.state.clientPaymentDetails.merchantId
//       }
//     });
//     postWithOutBaseUrl(this.state.clientPaymentDetails.localUrl, jsonToOpenGateWay, headers)
//       .then((response) => {
//         if (!response.ok) {
//           throw Error(response.statusText);
//         }
//         console.log('response', response);
//         return response.json();
//       }).then(rsp => {
//         if (rsp.data.paymentGatewayCommand.openPaymentGatewayData.result === 'SUCCESS') {
//           this.setState({ gatewayID: rsp.data.paymentGatewayCommand.openPaymentGatewayData.paymentGatewayId });
//           this.startPymtTransaction();
//         }
//       })
//       .catch(
//         (error) => {
//           console.log('PAYMENT GATWAY DETAILS', error)
//         }
//       )
//   }
  /*================METHOD TO START PAYMENT=======================*/
//   startPymtTransaction() {
//     let gatewayResult = new Object();

//     let paymentGatWayDetails = {};
//     let headers = {
//       'Accept': 'application/json',
//       'Content-Type': 'application/json'
//     }
//     let jsonToStartPayment = JSON.stringify({
//       method: this.state.clientPaymentDetails.localStartPymtTrans,
//       requestId: this.state.clientPaymentDetails.localRequestID,
//       targetType: this.state.clientPaymentDetails.localTargetType,
//       version: this.state.clientPaymentDetails.localVersion,
//       parameters: {
//         tokenizedCardNumber: null,
//         tenderedAmount: {
//           value: 10,
//           currencyCode: this.state.clientPaymentDetails.localCurrencyCode
//         },
//         paymentGatewayId: this.state.gatewayID,
//         originalTransId: null,
//         generateToken: false,
//         gratuityFlowEnabled: false,
//         cardType: 'credit',
//         discount: null,
//         partialApprovalAllowed: false,
//         baseTransactionAmount: {
//           value: Math.round(parseFloat(this.state.clientPaymentDetails.payAmount) * 100),
//           currencyCode: this.state.clientPaymentDetails.localCurrencyCode
//         },
//         addToken: false,
//         manual: false,
//         gratuityQuickSelections: null,
//         transactionType: this.state.clientPaymentDetails.localTransactionType,
//         isTaxInclusive: false,
//         taxAmounts: {
//           value: parseFloat(this.state.clientPaymentDetails.localTaxAmountsVal),
//           currencyCode: this.state.clientPaymentDetails.localCurrencyCode
//         },
//         gratuityAmount: {
//           value: 0.00,
//           currencyCode: this.state.clientPaymentDetails.localCurrencyCode
//         },
//         avsFields: null,
//         gratuityCustomAmountEntryAllowed: false,
//         tenderType: this.state.clientPaymentDetails.localTenderType,
//         note: null,
//         forced: false
//       }
//     });
//     postWithOutBaseUrl(this.state.clientPaymentDetails.localUrl, jsonToStartPayment, headers)
//       .then((response) => {
//         if (!response.ok) {
//           throw Error(response.statusText);
//         }
//         console.log('response', response);
//         return response.json();
//       }).then(rsp => {
//         if (rsp.statusDetails === "REQUEST_ACCEPTED") {
//           this.setState({ chanID: rsp.data.paymentGatewayCommand.chanId });
//         }
//         this.getPaymentTransactionStatus();

//       })
//       .catch(
//         (error) => {
//           console.log('PAYMENT GATWAY DETAILS', error)
//         })
//   }
//   getPaymentTransactionStatus() {
//     let gatewayResult = new Object();
//     let paymentGatWayDetails = {};
//     let headers = {
//       'Accept': 'application/json',
//       'Content-Type': 'application/json'
//     }
//     let jsonToGetTransStatus = JSON.stringify({
//       "method": this.state.clientPaymentDetails.localGetPymtTransStatus,
//       "requestId": this.state.clientPaymentDetails.localRequestID,
//       "targetType": this.state.clientPaymentDetails.localTargetType,
//       "parameters": {
//         "chanId": this.state.chanID,
//         "paymentGatewayId": this.state.gatewayID
//       }
//     });
//     postWithOutBaseUrl(this.state.clientPaymentDetails.localUrl, jsonToGetTransStatus, headers)
//       .then((response) => {
//         if (!response.ok) {
//           throw Error(response.statusText);
//         }
//         console.log('response', response);
//         return response.json();
//       }).then(result => {
//         console.log(result.data.paymentGatewayCommand.eventQueue);
//         if (result.data.paymentGatewayCommand.completed === false) {
//           setTimeout(
//             this.getPaymentTransactionStatus(), 1000);
//         } else {
//           let value = result.data.paymentGatewayCommand.paymentTransactionData.result;
//           this.closePaymentGateway();
//           if (value === 'APPROVED') {
//             let key1 = result.data.paymentGatewayCommand.paymentTransactionData;
//             const _transDate=(key1.date||'')==''?'':moment(key1.date).format('MM/DD/YYYY HH:mm:ss')
//             this.setState({authCd:key1.authCode,transStatus:key1.resultMessage,transDate:_transDate,cardType:key1.cardScheme,transId:key1.id,cardNum:key1.maskedPan});
//             this.closeWin();
//             this.successResponse();
//             return gatewayResult;
//           }
//           else if (value === 'PARTIALLY_APPROVED') {
//             let key1 = result.data.paymentGatewayCommand.paymentTransactionData;
//             const _transDate=(key1.date||'')==''?'':moment(key1.date).format('MM/DD/YYYY HH:mm:ss')
//             this.setState({authCd:key1.authCode,transStatus:key1.resultMessage,transDate:_transDate,cardType:key1.cardScheme,transId:key1.id,cardNum:key1.maskedPan});
//             this.closeWin();
//             this.successResponse();
//             return gatewayResult;
//           }
//           if (value === 'DECLINED') {
//             let key1 = result.data.paymentGatewayCommand.paymentTransactionData
//             const _transDate=(key1.date||'')==''?'':moment(key1.date).format('MM/DD/YYYY HH:mm:ss')
//             this.setState({authCd:key1.authCode,transStatus:key1.resultMessage,transDate:_transDate,cardType:key1.cardScheme,transId:key1.id,cardNum:key1.maskedPan});
//             this.closeWin();
//             this.failedResponse();
//             return gatewayResult;
//           }
//           if (value === 'FAILED') {
//             this.closeWin();
//             this.cancelledResponse();
//           }
//         }
//       })
//       .catch(
//         (error) => {
//           this.closePaymentGateway();
//           console.log('PAYMENT GATWAY DETAILS', error)
//         }
//       )
//   }

//   closePaymentGateway() {
//     let headers = {
//       'Accept': 'application/json',
//       'Content-Type': 'application/json'
//     };
//     let jsonToCloseGatWay = JSON.stringify({
//       "method": this.state.clientPaymentDetails.localGetPymtTransStatus,
//       "requestId": this.state.clientPaymentDetails.localRequestID,
//       "targetType": this.state.clientPaymentDetails.localTargetType,
//       "parameters": {
//         "chanId": this.state.chanID,
//         "paymentGatewayId": this.state.localOpenPymtGatewayMethodgatewayID
//       }
//     });
//     postWithOutBaseUrl(this.state.clientPaymentDetails.localUrl, jsonToCloseGatWay, headers)
//       .then((response) => {
//         if (!response.ok) {
//           throw Error(response.statusText);
//         }
//         console.log('response', response);
//         return response.json();
//       }).then(data => {
//         this.setState({loading:false});
//       })
//       .catch(
//         (error) => {
//           console.log('PAYMENT GATWAY DETAILS', error)
//         }
//       )
//   }
//   closeWin() {
//     let headers = {
//       'Accept': 'application/json',
//       'Content-Type': 'application/json'
//     };
//     let jsonToCloseWin = JSON.stringify({
//       "method": "cancelPaymentTransaction",
//       "requestId": this.state.clientPaymentDetails.localRequestID,
//       "targetType": this.state.clientPaymentDetails.localTargetType,
//       "version": "1.0",
//       "parameters": {
//         "paymentGatewayId": this.state.gatewayID,
//         "chanId": this.state.chanID
//       }
//     });
//     postWithOutBaseUrl(this.state.clientPaymentDetails.localUrl, jsonToCloseWin, headers)
//       .then((response) => {
//         if (!response.ok) {
//           throw Error(response.statusText);
//         }
//         console.log('response', response);
//         return response.json();
//       }).then(data => {
//       })
//       .catch(
//         (error) => {
//           console.log('PAYMENT GATWAY DETAILS', error)
//         }
//       )
//   }
  onSwipeClick() {
    this.openPaymentGateway();
  }
  failedResponse() {
    this.props.history.push('/paymentFailure?ssl_txn_id='+this.state.transId+'&ssl_result_message='+
    this.state.transStatus+'&ssl_txn_time='+this.state.transDate+'&cardType='+this.state.cardType+
    '&ssl_card_number='+this.state.cardNo+'&invoice_id='+this.state.invoiceId+'&ssl_amount='+this.state.amt+
    '&user_id='+this.state.userName+'&payment_location='+this.state.payment_location+'&ssl_approval_code='+this.state.authCd);
  }

  successResponse() {
    this.props.history.push('/paymentSuccess?ssl_txn_id='+this.state.transId+'&ssl_result_message='+
    this.state.transStatus+'&ssl_txn_time='+this.state.transDate+'&cardType='+this.state.cardType+
    '&ssl_card_number='+this.state.cardNo+'&invoice_id='+this.state.invoiceId+'&ssl_amount='+this.state.amt+
    '&user_id='+this.state.userName+'&payment_location='+this.state.payment_location+'&ssl_approval_code='+this.state.authCd)
  }

  cancelledResponse() {
    sessionStorage.setItem("cancelPaymentData", JSON.stringify({
      invoiceId: this.state.invoiceId,
      amount: this.state.amt,
    }))
    this.props.history.push('/paymentCancel')
  }
  render() {
    return (
      <div class="container">
        <div class="box">
          <div class="col-lg-12">
          <div className={"sweet-loading " + (false ? 'react-sweet-loader' : '')}>
          {/* <ClipLoader
            className={override}
            sizeUnit={"px"}
            size={100}
            color={'#123abc'}
            loading={this.state.loading}
          /> */}
        </div>
        <div className="count-down-react-box">
          <div className="count-down-react">
            <div className="title text-center col-8" style={{backgroundColor: "#00CED1", textAlign:"center",paddingBottom: "2%",
    marginBottom: "1%", marginLeft:"16%"}}>Time Left</div>
            <div className="text-center form-group">
              <CountdownTimer  hideDay hideHours color="#fff" backgroundColor="#16b4fc" count={600}/>
              {/* <CountdownTimer
                      count={600}
                      color="#fff"
                      backgroundColor="#16b4fc"
                      size={24}
                      labelSize={14}
                      minuteTitle="Min"
                      secondTitle="Sec"
                      onEnd={() => alert("Time's up!")}
                    /> */}
            </div>
          </div>
        </div>
            <div key="Payment">
              <div>
              <Cards
                  cvc={this.state.cvc}
                  expiry={this.state.expiry}
                  focused={this.state.focused}
                  name={this.state.name}
                  number={this.state.number}
                  callback={this.handleCallback}
                />
              </div>
              <div className="card-body text-center col-8" style={{marginLeft:"16%"}}>
              <h6 className="text-left">Order Section</h6>
                <form ref={c => (this.form = c)} onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    <div className="col-xs-6 col-xs-offset-3">
                      <input
                        type="tel"
                        name="invoiceNo"
                        className="form-control text-center"
                        required
                        placeholder="Invoice Number"
                        value={this.state.paymentInfo.invoiceId}
                        onChange={(e) => this.setState({ invoiceId: e.target.value })}
                      />
                      <input
                          type="tel"
                          name="amount"
                          className="form-control text-center"
                          placeholder="Amount"
                          required
                          value={this.state.paymentInfo.paymentAmt}
                          onChange={(e) => this.setState({ paymentAmt: e.target.value })}
                      />
                    </div>
                  </div>
                  <div className="class=" form-group>
                    <div className="col-xs-6 col-xs-offset-3">
                      <input
                        hidden={!this.state.cardHidden ? true : false}
                        type="tel"
                        name="number"
                        className="form-control"
                        placeholder="Account Data"
                        pattern="[\d| ]{16,22}"
                        required
                        value={''}
                        onFocus={this.handleInputFocus}
                      />
                      <small style={{paddingRight:"68%"}}>E.g.: 49..., 51..., 36..., 37...</small>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="col-xs-6 col-xs-offset-3">
                      <input
                        type="tel"
                        name="expiry"
                        hidden={!this.state.exDatehidden ? true : false}
                        className="form-control"
                        placeholder="Expiration Date(MMYY)"
                        pattern="\d\d/\d\d"
                        required
                        value={''}
                        onFocus={this.handleInputFocus}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="col-xs-6 col-xs-offset-3">
                      <input
                        type="tel"
                        name="tax"
                        hidden={!this.state.taxhidden ? true : false}
                        className="form-control"
                        placeholder="Sale Tax"
                        pattern="\d{3,4}"
                        required
                        value={this.state.slsTax}
                        onFocus={this.handleInputFocus}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      name="name"
                      hidden={!this.state.namehidden ? true : false}
                      className="form-control"
                      placeholder="Name"
                      required
                      value={this.state.name}
                      
                      onFocus={this.handleInputFocus}
                    />
                  </div>
                  <div className="form-group">
                    <div className="col-xs-6 col-xs-offset-3">
                      <input
                        type="tel"
                        name="cvc"
                        hidden={!this.state.cvchidden ? true : false}
                        className="form-control"
                        placeholder="CVC2"
                        pattern="\d{3,4}"
                        required
                        value={this.state.cvc2}
                        onFocus={this.handleInputFocus}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="col-xs-6 col-xs-offset-3">
                      <input
                        type="tel"
                        name="code"
                        hidden={!this.state.codehidden ? true : false}
                        className="form-control"
                        placeholder="Customer Code"
                        pattern="\d{3,4}"
                        required
                        value={this.state.customerCode}
                        onFocus={this.handleInputFocus}
                      />
                    </div>
                  </div>
                  {/* <input type="hidden" name="issuer" value={issuer} /> */}
                    {/* <div className="form-group d-flex justify-content-between">
                      <button type="submit" onClick={() => document.getElementById('myForm').submit()} class="btn btn-primary btn-block">Key in Numbers</button>
                      <button type="submit" class="btn btn-success btn-block" onClick={this.onSwipeClick}>Swipe</button>
                    </div> */}
                   <div className="form-group row">
                      <div className="col-md-4 col-sm-6">
                        <button type="submit" onClick={() => document.getElementById('myForm').submit()}
                                className="btn btn-success btn-block" style={{width:'106%'}}> Key in Numbers
                        </button>
                   </div>
                   <div className="col-md-4 col-sm-6">
                      <button type="submit" className="btn btn-success btn-block"
                              onClick={''} > Swipe
                      </button>
                   </div>
                 </div>
              </form>

                {/* {formData && (
                  <div className="App-highlight">
                    {formatFormData(formData).map((d, i) => <div key={i}>{d}</div>)}
                  </div>
                )} */}
                <hr />
               
              </div>
            
              <form ref={c => (this.form = c)} onSubmit={this.handleSubmit} hidden={!this.state.billinghidden ? true : false}>
                <h5>Billing Address</h5>
                <div className="class=" form-group>
                  <div className="col-xs-6 col-xs-offset-3">
                    <input
                      type="tel"
                      name="company"
                      className="form-control"
                      placeholder="Company"
                      pattern="[\d| ]{16,22}"
                      required
                      value={this.state.companyName}
                      onFocus={this.handleInputFocus}
                    />
                    <small>E.g.: Techmileage</small>
                  </div>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="First Name"
                    required
                    value={this.state.name1}
                    onFocus={this.handleInputFocus}
                  />
                </div>
                <div className="form-group">
                  <div className="col-xs-6 col-xs-offset-3">
                    <input
                      type="tel"
                      name="lastName"
                      className="form-control"
                      placeholder="Last Name"
                      pattern="\d\d/\d\d"
                      required
                      value={this.state.name2}
                      onFocus={this.handleInputFocus}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-xs-6 col-xs-offset-3">
                    <input
                      type="tel"
                      name="add1"
                      className="form-control"
                      placeholder="Address 1"
                      pattern="\d{3,4}"
                      required
                      value={this.state.address1}
                      onFocus={this.handleInputFocus}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-xs-6 col-xs-offset-3">
                    <input
                      type="tel"
                      name="add2"
                      className="form-control"
                      placeholder="Address 2"
                      pattern="\d{3,4}"
                      required
                      value={this.state.address2}
                      onFocus={this.handleInputFocus}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-xs-6 col-xs-offset-3">
                    <input
                      type="tel"
                      name="city"
                      className="form-control"
                      placeholder="City"
                      pattern="\d{3,4}"
                      required
                      value={this.state.city}
                      
                      onFocus={this.handleInputFocus}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-xs-6 col-xs-offset-3">
                    <input
                      type="tel"
                      name="state"
                      className="form-control"
                      placeholder="State/Province"
                      pattern="\d{3,4}"
                      required
                      value={this.state.stateName}
                      
                      onFocus={this.handleInputFocus}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-xs-6 col-xs-offset-3">
                    <input
                      type="tel"
                      name="code"
                      className="form-control"
                      placeholder="Postal Code"
                      pattern="\d{3,4}"
                      required
                      value={this.state.postalCode}
                      
                      onFocus={this.handleInputFocus}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-xs-6 col-xs-offset-3">
                    <input
                      type="tel"
                      name="countary"
                      className="form-control"
                      placeholder="Country"
                      pattern="\d{3,4}"
                      required
                      value={this.state.countaryName}
                      
                      onFocus={this.handleInputFocus}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-xs-6 col-xs-offset-3">
                    <input
                      type="tel"
                      name="phone"
                      className="form-control"
                      placeholder="Phone"
                      pattern="\d{3,4}"
                      required
                      value={this.state.PhoneNo}
                      
                      onFocus={this.handleInputFocus}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-xs-6 col-xs-offset-3">
                    <input
                      type="tel"
                      name="cvc"
                      className="form-control"
                      placeholder="E-mail Address"
                      pattern="\d{3,4}"
                      required
                      value={this.state.email}
                      
                      onFocus={this.handleInputFocus}
                    />
                  </div>
                </div>
                {/* <input type="hidden" name="issuer" value={issuer} /> */}
                <div className="form-actions">
                  <div className="form-inline"><button type="submit" class="form-control btn btn-success btn-md">Proceed</button>&nbsp;&nbsp;
            <button type="submit" class=" form-control btn btn-success btn-md">Do you still want to Swipe</button></div>
                </div>
              </form>
              <form method='post' id="myForm" action={this.state.clientPaymentDetails.paymentFormUrlKeyInNumbers} onSubmit={this.state.save}>
                <input type='hidden' name='ssl_merchant_id' value={this.state.clientPaymentDetails.merchantId} />
                <input type='hidden' name='ssl_user_id' value={this.state.clientPaymentDetails.userId} />
                <input type='hidden' name='ssl_pin' value={this.state.localPinKeyInNumber} />
                <input type='hidden' name='ssl_transaction_type' value={this.state.clientPaymentDetails.transactionType} />
                <input type='hidden' name='ssl_show_form' value={this.state.clientPaymentDetails.showForm} />
                <input type='hidden' name='ssl_amount' value={this.state.amt} />
                <input type='hidden' name='ssl_partial_auth_indicator' value={this.state.partialAuthIndicator} />
                <input type='hidden' name='ssl_cvv2cvc2' value={this.state.cvv2cvc2} />
                <input type='hidden' name='ssl_cvv2cvc2_indicator' value={this.state.cvv2cvc2Indicator} />
                <input type='hidden' name='ssl_avs_zip' value={this.state.avs_zip} />
                <input type='hidden' name='ssl_avs_address' value={this.state.avs_address} />
                <input type='hidden' name='ssl_invoice_number' value={this.state.invoiceId} />
                <input type='hidden' name='invoice_id' value={this.state.invoiceId} />
                <input type='hidden' name='user_id' value={this.state.clientPaymentDetails.actUserId} />
                <input type='hidden' name='payment_location' value={this.state.clientPaymentDetails.paymentLocation} />
                {/* <input type='hidden' name='ssl_first_name' value={this.state.clientPaymentDetails.payeeFirstName} />
                <input type='hidden' name='ssl_last_name' value={this.state.clientPaymentDetails.payeeLastName} /> */}
                <input type='hidden' name='ssl_first_name' maxLength='20' value={(this.state.clientPaymentDetails.payeeFirstName||'')!==''?this.state.clientPaymentDetails.payeeFirstName.substring(0,20):this.state.clientPaymentDetails.payeeFirstName} />
                <input type='hidden' name='ssl_last_name' maxLength='30' value={(this.state.clientPaymentDetails.payeeLastName||'')!==''?this.state.clientPaymentDetails.payeeLastName.substring(0,30):this.state.clientPaymentDetails.payeeLastName} />
                <input type='hidden' name='ssl_avs_address' value={this.state.clientPaymentDetails.payeeAddress} />
                <input type='hidden' name='ssl_city' value={this.state.clientPaymentDetails.payeeCity} />
                <input type='hidden' name='ssl_state' value={this.state.clientPaymentDetails.payeeState} />
                <input type='hidden' name='ssl_avs_zip' value={this.state.clientPaymentDetails.payeeZip} />
                <input type='hidden' name='ssl_email' value={this.state.clientPaymentDetails.payeeEmail} />
                <input type='hidden' name='ssl_phone' value={this.state.clientPaymentDetails.payeePhoneNo} />
                <input type='hidden' name='localUrl' value={this.state.clientPaymentDetails.localUrl} />
                <input type='hidden' name='localOpenPymtGatewayMethod' value={this.state.clientPaymentDetails.localOpenPymtGatewayMethod} />
                <input type='hidden' name='localRequestID' value={this.state.clientPaymentDetails.localRequestID} />
                <input type='hidden' name='localVersion' value={this.state.clientPaymentDetails.localVersion} />
                <input type='hidden' name='localTargetType' value={this.state.clientPaymentDetails.localTargetType} />
                <input type='hidden' name='localStartPymtTrans' value={this.state.clientPaymentDetails.localStartPymtTrans} />
                <input type='hidden' name='localCurrencyCode' value={this.state.clientPaymentDetails.localCurrencyCode} />
                <input type='hidden' name='localTransactionType' value={this.state.clientPaymentDetails.localTransactionType} />
                <input type='hidden' name='localTenderType' value={this.state.clientPaymentDetails.localTenderType} />
                <input type='hidden' name='localGratuityAmountVal' value={this.state.clientPaymentDetails.localGratuityAmountVal} />
                <input type='hidden' name='localTaxAmountsVal' value={this.state.clientPaymentDetails.localTaxAmountsVal} />
                <input type='hidden' name='localGetPymtTransStatus' value={this.state.clientPaymentDetails.localGetPymtTransStatus} />
                <input type='hidden' name='localApp' value={this.state.clientPaymentDetails.localApp} />
                <input type='hidden' name='localEmail' value={this.state.clientPaymentDetails.localEmail} />
                <input type='hidden' name='localEnvironmentType' value={this.state.clientPaymentDetails.localEnvironmentType} />
                <button type="submit" hidden="true" class=" form-control btn btn-success btn-md">Proceed</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
