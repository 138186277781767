import {GET_ACCOUNTS_DROPDOWN,GET_ALL_ACCOUNTS,
    GET_ACCOUNT_REC,GET_ASSIGN_COM_REC, SET_SELECTED_ACCOUNT_RATE_PLAN, LIST_OF_ACCOUNTS_LINK} from '../actionTypes';
import {moduleTypes} from '../../util/constants';
import {getAccessToken} from '../authenticateActions'
import {getAxiosInstance} from '../../util/service';
import {store} from '../../store/configureStore';
import { getCompanyId ,isManagement} from '../../util/customMethods';

export const addLink=(obj)=>{
    return async (dispatch) => {
        try{
            const _url =`account/generateAccLink/${obj.acc_Id}/${obj.gp_name}?access_token=${getAccessToken()}&id=${obj.id}`;
            const response=await getAxiosInstance(moduleTypes.ADMIN).put(_url);
            return {statusCode:response.data.statusCode,statusMsg:response.data.statusMsg}
        }catch(ex){
            return {statusCode:500,statusMsg:'An unexpected error occurred.'}
        }
    }
}

export const deleteLink=(id)=>{
    return async (dispatch) => {
        try{
            const _url =`account/deleteLink/${id}?access_token=${getAccessToken()}`;
            const response=await getAxiosInstance(moduleTypes.ADMIN).delete(_url);
            return {statusCode:response.data.statusCode,statusMsg:response.data.statusMsg}
        }catch(ex){
            return {statusCode:500,statusMsg:'An unexpected error occurred.'}
        }
    }
}

export const getListOfAccountsLink=(id)=>{
    return async (dispatch) => {
        try{
            const _url =`account/getAccLinkList/${id}?access_token=${getAccessToken()}`;
            const response=await getAxiosInstance(moduleTypes.ADMIN).get(_url);
            if(response.data.statusCode===200){
                dispatch({type:LIST_OF_ACCOUNTS_LINK,listOfAccountsLink:response.data.resp})
            }else{
                dispatch({type:LIST_OF_ACCOUNTS_LINK,listOfAccountsLink:undefined})
            }
            return {statusCode:response.data.statusCode,statusMsg:response.data.statusMsg}
        }catch(ex){
            return {statusCode:500,statusMsg:'An unexpected error occurred.'}
        }
    }
}

export const getAccountListForDropDown=()=>{
    return async (dispatch) => {
        try{
            const resp=await getAxiosInstance(moduleTypes.MANAGE).get(`company/${getCompanyId()}/getAccountAssignOpt?access_token=${getAccessToken()}`);
            if(resp.data.statusCode===200){
                dispatch({type:GET_ACCOUNTS_DROPDOWN,payload:resp.data.response})
            }else{
                dispatch({type:GET_ACCOUNTS_DROPDOWN,payload:undefined})
            }
        }catch(ex){
            dispatch({type:GET_ACCOUNTS_DROPDOWN,payload:undefined})
        }
    }
}
export const getAccountList=(searchObj)=>{
    const _url=`account/searchNameAndType/${isManagement()}?searchQuery=${searchObj.searchQuery}&limit=${searchObj.limit}&accountName=${searchObj.accountName}&accountType=${searchObj.accountType}&accountStatus=${searchObj.accountStatus}&companyId=${getCompanyId()}&access_token=${getAccessToken()}`;
    return async (dispatch) => {
        try{
            const resp=await getAxiosInstance(moduleTypes.ADMIN).get(_url);
            if(resp.status===200){
                dispatch({type:GET_ALL_ACCOUNTS,payload:resp.data})
            }else{
                dispatch({type:GET_ALL_ACCOUNTS,payload:undefined})
            }
        }catch(ex){
            dispatch({type:GET_ALL_ACCOUNTS,payload:undefined})
        }
    }
}
export const getServiceRatePlans=(accountId)=>{
    let _url=`account/select/accountBasicInfo?accountId=${accountId}&companyId=${getCompanyId()}&corporateId=1&access_token=4{getAccessToken()}`
    return async (dispatch) => {
        //dispatch(spinnerActions.showSpinner());
        let _accountBasicInfo=(store.getState()).accountList.accountBasicInfo;
        try{
            const response=await getAxiosInstance(moduleTypes.ADMIN).get(_url);        
            if(response.status===200) { 
                _accountBasicInfo.accRatePlanAndDiscountInfoByName=response.data.accRatePlanAndDiscountInfoByName  
                dispatch({
                    type: GET_ACCOUNT_REC,
                    accountBasicInfo:Object.assign({},_accountBasicInfo)
                });
            } else{
                
                    dispatch({
                        type: GET_ACCOUNT_REC,
                        accountBasicInfo:Object.assign({},_accountBasicInfo)
                    });
            }
           // dispatch(spinnerActions.hideSpinner());
        }
        catch(error ){
          //dispatch(spinnerActions.hideSpinner());
          dispatch({
                type: GET_ACCOUNT_REC,
                accountBasicInfo:Object.assign({},_accountBasicInfo)
            });
          }
    }
}
export const onAccountServiceCallRateSubmit=(serviceCallRec,accountId)=>{
    return async(dispatch)=>{
        let _url=`account/callRateAndDiscount?companyId=${getCompanyId()}&access_token=${getAccessToken()}`;
        serviceCallRec.serviceCallRate.companyId= getCompanyId();
        serviceCallRec.serviceCallRate.corporateId=1;
        serviceCallRec.serviceCallRate.accountId=accountId;
        serviceCallRec.serviceCallRateDiscount.companyId=getCompanyId();
        serviceCallRec.serviceCallRateDiscount.corporateId=1;
        serviceCallRec.serviceCallRateDiscount.accountId=accountId;
        //dispatch(spinnerActions.showSpinner());
        try{
        const response=await getAxiosInstance(moduleTypes.ADMIN).post(_url,serviceCallRec)             
                if(response.status===200) {
                    //success(response.message);
                    dispatch(getServiceRatePlans(accountId))
                    return {statusCode:response.status,statusMsg:response.data.message}
                } else{
                        //fail(response.message);
                        return {statusCode:null,statusMsg:response.data.message}
                }
               // dispatch(spinnerActions.hideSpinner());
            }
            catch(error) {
               // dispatch(spinnerActions.hideSpinner());
                //fail(error)
                return {statusCode:null,statusMsg:error.message}
            };
    }
}
export const setAccountNotesToList=(notesObj,index)=>{
    return async(dispatch)=>{
        let successMessage="Account Note Added/Updated successfully";
        if(notesObj.accountId===0){
            let _accountBasicInfo=(store.getState()).accountList.accountBasicInfo;
            if(index!==-1){
                _accountBasicInfo.accountNotesForm.accountNotes[index]=notesObj;
            }else{
                _accountBasicInfo.accountNotesForm.accountNotes.push(notesObj);
            }
            dispatch({
                type:GET_ACCOUNT_REC,
                accountBasicInfo: Object.assign({},_accountBasicInfo)
            });
            return {statusCode:200,statusMsg:successMessage}
        }else{
            if(notesObj.notesId===0){
            let _url=`account/insertAccountNotes?companyId=${getCompanyId()}&access_token=${getAccessToken()}`
            //dispatch(spinnerActions.showSpinner());
            try{
             const response=await getAxiosInstance(moduleTypes.ADMIN).post(_url,notesObj) 
                if(response.status===200) {
                    //success(response.message);
                    dispatch(getAccountNotesList(notesObj.accountId))
                    return {statusCode:response.status===200,statusMsg:response.data.message}
                } else{
                    return {statusCode:null,statusMsg:response.data.message}
                    }
                //dispatch(spinnerActions.hideSpinner());
            }
            catch(error ){
                //dispatch(spinnerActions.hideSpinner());
                return {statusCode:null,statusMsg:error.message}
            };
        } else{
            let _url=`account/updateAccountNotes?companyId=${getCompanyId()}&access_token=${getAccessToken()}`
            try{
            //dispatch(spinnerActions.showSpinner());
             const response=await getAxiosInstance(moduleTypes.ADMIN).put(_url,notesObj)
                if(response.status===200) {
                    //success(response.message);
                    dispatch(getAccountNotesList(notesObj.accountId));
                    return {statusCode:response.status,statusMsg:response.data.message}
                } else{
                    return {statusCode:null,statusMsg:response.data.message}
                }
                //dispatch(spinnerActions.hideSpinner());
            }
            catch(error) {
               // dispatch(spinnerActions.hideSpinner());
               return {statusCode:error,statusMsg:error.message}
            };
        }       
    }
    }
}
export const getBillingNodes=(accountId)=>{
    let _url=`account/select/accountBasicInfo?accountId=${accountId}&companyId=${getCompanyId()}&corporateId=1&access_token=${getAccessToken()}`
    return async (dispatch) => {
        //dispatch(spinnerActions.showSpinner());
        let _accountBasicInfo=(store.getState()).accountList.accountBasicInfo;
        try{
         const response=await getAxiosInstance(moduleTypes.ADMIN).get(_url)        
            if(response.status===200) { 
                _accountBasicInfo.billingNotesForm.billingNotes=response.data.billingNotesForm.billingNotes  
                dispatch({
                    type: GET_ACCOUNT_REC,
                    accountBasicInfo:Object.assign({},_accountBasicInfo)
                });
            } else{
                    dispatch({
                        type: GET_ACCOUNT_REC,
                        accountBasicInfo:Object.assign({},_accountBasicInfo)
                    });
            }
            //dispatch(spinnerActions.hideSpinner());
        }
        catch(error ) {
         // dispatch(spinnerActions.hideSpinner());
          };
    }
}
export const setBillingNotesToList=(notesObj,index)=>{
    return async (dispatch)=>{
        let successMessage="Account Note Added/Updated successfully";
        if(notesObj.accountId===0){
            let _accountBasicInfo=(store.getState()).accountList.accountBasicInfo;
            if(index!==-1){
                _accountBasicInfo.billingNotesForm.billingNotes[index]=Object.assign({},notesObj);
            }else{
                _accountBasicInfo.billingNotesForm.billingNotes.push(Object.assign({},notesObj));
            }
            dispatch({
                type: GET_ACCOUNT_REC,
                accountBasicInfo: Object.assign({},_accountBasicInfo)
            });
            return {statusCode:200,statusMsg:successMessage}
        }else{
            if(notesObj.notesId===0){
            let _url=`account/insertBillingNotes?companyId=${getCompanyId()}&access_token=${getAccessToken()}`
            try{
             const response=await getAxiosInstance(moduleTypes.ADMIN).post(_url,notesObj) 
                if(response.data.statusCode===200) {
                    dispatch(getBillingNodes(notesObj.accountId));
                    return {statusCode:response.data.statusCode,statusMsg:response.data.message}
                } else{
                    return {statusCode:null,statusMsg:response.data.message}
                }
            }
            catch(error) {
                return {statusCode:null,statusMsg:error.message}
            }
        } else{
            let _url=`account/updateBillingNotes?companyId=${getCompanyId()}&access_token=${getAccessToken()}`
            //dispatch(spinnerActions.showSpinner());
            try{
             const response=await getAxiosInstance(moduleTypes.ADMIN).put(_url,notesObj)             
                if(response.data.statusCode===200) {
                    //success(response.message);
                    dispatch(getBillingNodes(notesObj.accountId))
                    return {statusCode:response.data.statusCode,statusMsg:response.data.message}
                } else{
                   
                    return {statusCode:null,statusMsg:response.data.message}
                }
                //dispatch(spinnerActions.hideSpinner());
            }
            catch(error ) {
                //dispatch(spinnerActions.hideSpinner());
                return {statusCode:null,statusMsg:error.message}
            };
        }       
    } 
    }
}

export const getAccountNotesList=(accountId)=>{
    let _url=`account/select/accountBasicInfo?accountId=${accountId}&companyId=${getCompanyId()}&corporateId=1&access_token=${getAccessToken()}`
    return async (dispatch) => {
        //dispatch(spinnerActions.showSpinner());
        let _accountBasicInfo=(store.getState()).accountList.accountBasicInfo;
        try{
        const response=await getAxiosInstance(moduleTypes.ADMIN).get(_url)         
            if(response.status===200) { 
                _accountBasicInfo.accountNotesForm.accountNotes=response.data.accountNotesForm.accountNotes  
                dispatch({
                    type: GET_ACCOUNT_REC,
                    accountBasicInfo:Object.assign({},_accountBasicInfo)
                });
            } else{
                    dispatch({
                        type: GET_ACCOUNT_REC,
                        accountBasicInfo:Object.assign({},_accountBasicInfo)
                    });
                }
            //dispatch(spinnerActions.hideSpinner());
        }
        catch(error){
          //dispatch(spinnerActions.hideSpinner());
          dispatch({
            type: GET_ACCOUNT_REC,
            accountBasicInfo:Object.assign({},_accountBasicInfo)
        });
        }
    }
}
export const setAccountContactToList=(contactObj,index)=>{    
    return async(dispatch)=>{
        let successMessage="Contact Added/Updated successfully";
        if(contactObj.accountId===0){
            let _accountBasicInfo=(store.getState()).accountList.accountBasicInfo;
            if(index!==-1){
                _accountBasicInfo.accountContactForm.accountContacts[index]=contactObj;
            }else{
                _accountBasicInfo.accountContactForm.accountContacts.push(contactObj);
            }
            dispatch({
                type: GET_ACCOUNT_REC,
                accountBasicInfo: Object.assign({},_accountBasicInfo)
            });
            return {statusCode:200,statusMsg:successMessage}
        }else{
            let _url=`account/insertAccountContact?companyId=${getCompanyId()}&access_token=${getAccessToken()}`
           // dispatch(spinnerActions.showSpinner());
            try{
                const response=await getAxiosInstance(moduleTypes.ADMIN).post(_url,{"accountContacts":[contactObj]})            
                if(response.status===200) {
                    //success(response.message);
                    dispatch(getAccountContactList(contactObj.accountId));
                    return {statusCode:response.status,statusMsg:response.data.message}
                } else{
                    return {statusCode:null,statusMsg:response.data.message}
                }
                //dispatch(spinnerActions.hideSpinner());
            }
            catch(error) {
                //dispatch(spinnerActions.hideSpinner());
                return {statusCode:null,statusMsg:error.message}
            };
        }        
    }
}
export const getAccountContactList=(accountId)=>{
    let _url=`account/select/accountContact?accountId=${accountId}&companyId=${getCompanyId()}&corporateId=1&access_token=${getAccessToken()}`
    return async(dispatch) => {
        //dispatch(spinnerActions.showSpinner());
        let _accountBasicInfo=(store.getState()).accountList.accountBasicInfo;
        try{
        const response=await getAxiosInstance(moduleTypes.ADMIN).get(_url)
            if(response.status===200) { 
                _accountBasicInfo.accountContactForm.accountContacts=response.data.accountContacts   
                dispatch({
                    type: GET_ACCOUNT_REC,
                    accountBasicInfo:Object.assign({},_accountBasicInfo)
                });
            } else{
                dispatch({
                    type: GET_ACCOUNT_REC,
                    accountBasicInfo:Object.assign({},_accountBasicInfo)
                });
            }
            //dispatch(spinnerActions.hideSpinner());
        }
        catch(error) {
          //dispatch(spinnerActions.hideSpinner());
            dispatch({
                type: GET_ACCOUNT_REC,
                accountBasicInfo:Object.assign({},_accountBasicInfo)
            });
          }
    }
}

export const getAssignedCompList=(accountId)=>{
    let assignedComList=(store.getState()).accountList.assignedComList;
    let _url=`/company/accAssignedCompanies?accountId=${accountId}&access_token=${getAccessToken()}`
    return async(dispatch) => {
        try{
        const response=await getAxiosInstance(moduleTypes.DISPATCH).get(_url)
            if(response.data.statusCode===200) {  
                dispatch({
                    type: GET_ASSIGN_COM_REC,
                    assignedComList:response.data.response
                });
            } else{
                    dispatch({
                        type: GET_ASSIGN_COM_REC,
                        assignedComList:[]
                    });
            }
        }
        catch(error) {
            dispatch({
                type: GET_ASSIGN_COM_REC,
                assignedComList:[]
            });
          }
    }
}

export const getBillingContactList=(accountId)=>{
    let _url=`account/select/billingContact?accountId=${accountId}&companyId=${getCompanyId()}&corporateId=1&access_token=${getAccessToken()}`
    return async(dispatch) => {
       // dispatch(spinnerActions.showSpinner());
        let _accountBasicInfo=(store.getState()).accountList.accountBasicInfo;
        try{
        const response=await getAxiosInstance(moduleTypes.ADMIN).get(_url)
            if(response.status===200) { 
                _accountBasicInfo.billingContactForm.accountContacts=response.data.accountContacts  
                dispatch({
                    type: GET_ACCOUNT_REC,
                    accountBasicInfo:Object.assign({},_accountBasicInfo)
                });
            } else{
                    dispatch({
                        type: GET_ACCOUNT_REC,
                        accountBasicInfo:Object.assign({},_accountBasicInfo)
                    });
            }
            //dispatch(spinnerActions.hideSpinner());
        }
        catch(error) {
          //dispatch(spinnerActions.hideSpinner());
            dispatch({
                type: GET_ACCOUNT_REC,
                accountBasicInfo:Object.assign({},_accountBasicInfo)
            });
          }
    }
}
export const setBillingContactToList=(contactObj,index,isDefaultAddress=false)=>{
    return async (dispatch)=>{
        let successMessage="Billing Contact Added/Updated successfully";
        let _accountBasicInfo=(store.getState()).accountList.accountBasicInfo;
        if(contactObj.accountId===0){            
            if(isDefaultAddress){
                _accountBasicInfo.billingContactForm.accountContacts.map((contact)=>{
                    contact.defaultEmailId=0;
                })
            }
            if(index!==-1){                
                _accountBasicInfo.billingContactForm.accountContacts[index]=contactObj;
            }else{
                _accountBasicInfo.billingContactForm.accountContacts.push(contactObj);
            }
            dispatch({
                type: GET_ACCOUNT_REC,
                accountBasicInfo: Object.assign({},_accountBasicInfo)
            });
           // success(successMessage)           
          return {statusCode:200,statusMsg:successMessage}
        }else{
            let _url=`account/insertBillingContact?companyId=${getCompanyId()}&access_token=${getAccessToken()}`
            //dispatch(spinnerActions.showSpinner());
            let reqObj={"accountContacts":[]};
            if(isDefaultAddress){
                _accountBasicInfo.billingContactForm.accountContacts.map((contact)=>{
                    contact.defaultEmailId=0;
                })
                _accountBasicInfo.billingContactForm.accountContacts[index]=contactObj;
                reqObj.accountContacts=_accountBasicInfo.billingContactForm.accountContacts
            }else{
                reqObj.accountContacts.push(contactObj);
            }            
            try{
                const response=await getAxiosInstance(moduleTypes.ADMIN).post(_url,reqObj)
                if(response.data.statusCode===200) {
                    //success(response.message);
                    dispatch(getBillingContactList(contactObj.accountId))
                    return {statusCode:response.data.statusCode,statusMsg:response.data.message}
                } else{                    
                    //fail(response.message);
                    return {statusCode:null,statusMsg:response.data.message}
                }
                //dispatch(spinnerActions.hideSpinner());
            }
            catch(error) {
                //dispatch(spinnerActions.hideSpinner());
                return {statusCode:null,statusMsg:error.message}
            }
        }
    }
}
export const deleteContactToList=(accountId,index,type)=>{
    return async (dispatch)=>{
        let successMessage="Contact deleted successfully";
        let _accountBasicInfo=(store.getState()).accountList.accountBasicInfo;
        if(accountId===0){
            if(type==='ACCOUNT'){
                _accountBasicInfo.accountContactForm.accountContacts.splice(index,1);
            }
            if(type==='BILLING'){
                _accountBasicInfo.billingContactForm.accountContacts.splice(index,1);
            }                        
            dispatch({
                type: GET_ACCOUNT_REC,
                accountBasicInfo: Object.assign({},_accountBasicInfo)
            });
            return {statusCode:200,statusMsg:successMessage}
        }else{
            let id=0;
            if(type==='ACCOUNT'){
                id=_accountBasicInfo.accountContactForm.accountContacts[index].contactId;
            }
            if(type==='BILLING'){
               id=_accountBasicInfo.billingContactForm.accountContacts[index].contactId;
            }
            let _url=`account/deleteContactInfo?contactId=${id}&contactType=${type}&access_token=${getAccessToken()}`
            //dispatch(spinnerActions.showSpinner());                  
            try{
                const response=await getAxiosInstance(moduleTypes.ADMIN).delete(_url)
            
                if(response.data.statusCode===200) {
                    //success(response.message);
                    if(type==='ACCOUNT'){
                        dispatch(getAccountContactList(accountId))
                    }
                    if(type==='BILLING'){
                        dispatch(getBillingContactList(accountId))
                    }
                    return {statusCode:response.data.statusCode,statusMsg:response.data.message}
                    
                } else{
                        return {statusCode:null,statusMsg:response.data.message}
                    }
                //dispatch(spinnerActions.hideSpinner());
            }
            catch(error) {
                //dispatch(spinnerActions.hideSpinner());
                return {statusCode:null,statusMsg:error.message}
            }
        }
    }
}
export const deleteNoteToList=(accountId,index,type)=>{
    return async (dispatch)=>{
        let successMessage="Node deleted successfully";
        let _accountBasicInfo=(store.getState()).accountList.accountBasicInfo;
        if(accountId===0){
            if(type==='ACCOUNT'){
                _accountBasicInfo.accountNotesForm.accountNotes.splice(index,1);
            }
            if(type==='BILLING'){
                _accountBasicInfo.billingNotesForm.billingNotes.splice(index,1);
            }                        
            dispatch({
                type: GET_ACCOUNT_REC,
                accountBasicInfo: Object.assign({},_accountBasicInfo)
            });
            return {statusCode:200,statusMsg:successMessage}
        }else{
            let id=0;
            let _url=''
            if(type==='ACCOUNT'){
                id=_accountBasicInfo.accountNotesForm.accountNotes[index].notesId;
                _url=`account/deleteAccountNotes?companyId=${getCompanyId()}&actNotesId=${id}&access_token=${getAccessToken()}`
            }
            if(type==='BILLING'){
               id=_accountBasicInfo.billingNotesForm.billingNotes[index].notesId;
               _url=`account/deleteBillingNotes?billingNotesId=${id}&companyId=${getCompanyId()}&access_token=${getAccessToken()}`
            } 
            //dispatch(spinnerActions.showSpinner()); 
            try{                 
             const response=await getAxiosInstance(moduleTypes.ADMIN).delete(_url)
             
                if(response.data.statusCode===200) {
                    //success(response.message);
                    if(type==='ACCOUNT'){
                        dispatch(getAccountNotesList(accountId))
                    }
                    if(type==='BILLING'){
                        dispatch(getBillingNodes(accountId))
                    }
                    return {statusCode:response.data.statusCode,statusMsg:response.data.message}
                    
                } else{
                    return {statusCode:null,statusMsg:response.data.message}
                }
                //dispatch(spinnerActions.hideSpinner());
            }
            catch(error) {
                //dispatch(spinnerActions.hideSpinner());
                return {statusCode:null,statusMsg:error.message}
            }
        }
    }
}
export const clearAccountRec=()=>{
    return (dispatch)=>{
       // dispatch(spinnerActions.showSpinner());
        dispatch({            
            type: GET_ACCOUNT_REC,
            accountBasicInfo:undefined
        });
        dispatch({            
            type: SET_SELECTED_ACCOUNT_RATE_PLAN,
            selectedAccRatePlan:undefined
        });
    }
}

export const accountBasicInfoFieldChange=(keyName,value)=>{
    return (dispatch)=>{
        let _accountBasicInfo={}
        if( Object.keys((store.getState()).accountList).length>0){
            _accountBasicInfo= (store.getState()).accountList.accountBasicInfo;
            _accountBasicInfo[keyName]=value
           if(_accountBasicInfo){
            dispatch({
                type: GET_ACCOUNT_REC,
                accountBasicInfo: _accountBasicInfo
            })
           }  
      }
    }
}

export const accountBillableChange=(index,keyName,value)=>{
    return (dispatch)=>{
        let _accountBasicInfo={}
        if( Object.keys((store.getState()).accountList).length>0){
            _accountBasicInfo= (store.getState()).accountList.accountBasicInfo;
            _accountBasicInfo.accountBillingList[index][keyName]=value
           if(_accountBasicInfo){
            dispatch({
                type: GET_ACCOUNT_REC,
                accountBasicInfo: _accountBasicInfo
            })
           }  
      }
    }
}

export const addRemoveBillableIndex=(index)=>{
    return async (dispatch)=>{
        let _accountBasicInfo={}
        let filterData = [];
        if( Object.keys((store.getState()).accountList).length>0){
            _accountBasicInfo= (store.getState()).accountList.accountBasicInfo;
            if(index==0)
            _accountBasicInfo.accountBillingList.push({abid: 0,name:"",accountId: null,billableRate: 0,billableType: "",fromDate: "",toDate: ""});
        else{
            filterData =  _accountBasicInfo.accountBillingList.filter(val => {
                return val.abid != null });
                _accountBasicInfo.accountBillingList=filterData;
        }
           if(_accountBasicInfo){
            dispatch({
                type: GET_ACCOUNT_REC,
                accountBasicInfo: _accountBasicInfo
            })
           }  
      }
    }
}

export const addUpdatBillableIndex=(index,accountId)=>{
    return async(dispatch)=>{
        let _accountBasicInfo={}
        let filterObj = {};
        if( Object.keys((store.getState()).accountList).length>0){
            _accountBasicInfo= (store.getState()).accountList.accountBasicInfo;
            _accountBasicInfo.accountBillingList[index]['accountId']=accountId;
            filterObj =  _accountBasicInfo.accountBillingList[index];
                let _url=`account/accountBilling?companyId=${getCompanyId()}&access_token=${getAccessToken()}`
                 try{
                     const response=await getAxiosInstance(moduleTypes.ADMIN).post(_url,JSON.stringify(filterObj),{
                        headers: {
                            'Content-Type': 'application/json',
                        }})            
                     if(response.status===200) {
                        if(_accountBasicInfo){
                            _accountBasicInfo.accountBillingList[index].abid=response.data.accountId;
                            dispatch({
                                type: GET_ACCOUNT_REC,
                                accountBasicInfo: _accountBasicInfo
                            })
                           }  
                         return {statusCode:response.status,statusMsg:response.data.message}
                     } else{
                         return {statusCode:null,statusMsg:response.data.message}
                     }
                 }
                 catch(error) {
                     return {statusCode:null,statusMsg:error.message}
                 };
          
      }
    }
}

export const accountTimePlanFieldChange=(keyName,value)=>{
    return (dispatch)=>{
        let _accountBasicInfo={}
        if( Object.keys((store.getState()).accountList).length>0){
            _accountBasicInfo=(store.getState()).accountList.accountBasicInfo ;
            _accountBasicInfo[keyName]=value
           if(_accountBasicInfo){
            dispatch({
                type: GET_ACCOUNT_REC,
                accountBasicInfo:_accountBasicInfo
            })
           }  
      }
    }
}
export const setBillableStatus=(event,chargeListId)=>{
    return (dispatch) => {
        let _accountBasicInfo = {}
        if (Object.keys((store.getState()).accountList).length > 0) {
            _accountBasicInfo = (store.getState()).accountList.accountBasicInfo;
    let idx=  _accountBasicInfo.accountBillableChargeList.findIndex(x => x.idChargeList ==chargeListId);
    if(idx!==-1)
    _accountBasicInfo.accountBillableChargeList[idx].isBillable=event.target.checked;
       dispatch({
        type: GET_ACCOUNT_REC,
        accountBasicInfo: _accountBasicInfo
    });
    }
}
}
export const accountDefaultLocationFieldChange=(type,keyName,value)=>{
    return (dispatch)=>{
        let _accountBasicInfo={}
        if( Object.keys((store.getState()).accountList).length>0){
            _accountBasicInfo= (store.getState()).accountList.accountBasicInfo;
           if(_accountBasicInfo){
            let _accountDefaultLocation=_accountBasicInfo.accountDefaultLocation;
            _accountDefaultLocation[type][keyName]=value;
            _accountBasicInfo.accountDefaultLocation=_accountDefaultLocation;
            dispatch({
                type: GET_ACCOUNT_REC,
                accountBasicInfo:_accountBasicInfo
            })
           }
        }
    }
}
export const accountDefaultLocationAddressChange=(type,objAddress)=>{
    return (dispatch)=>{
        let _accountBasicInfo={}
        if( Object.keys((store.getState()).accountList).length>0){
            _accountBasicInfo= (store.getState()).accountList.accountBasicInfo;
           if(_accountBasicInfo){
            let _accountDefaultLocation=_accountBasicInfo.accountDefaultLocation;
            _accountDefaultLocation[type]=objAddress;
            _accountBasicInfo.accountDefaultLocation=_accountDefaultLocation;
            dispatch({
                type: GET_ACCOUNT_REC,
                accountBasicInfo:JSON.parse(JSON.stringify(_accountBasicInfo))
            })
           }
        }
    }
}
export const accountAddressFieldChange=(keyName,value)=>{
    return (dispatch)=>{
        let _accountBasicInfo={}
        if( Object.keys((store.getState()).accountList).length>0){
            _accountBasicInfo= (store.getState()).accountList.accountBasicInfo;
           if(_accountBasicInfo){
            let _accountAddress=_accountBasicInfo.accountAddress;
            _accountAddress[keyName]=value;
            _accountBasicInfo.accountAddress=_accountAddress;
            dispatch({
                type: GET_ACCOUNT_REC,
                accountBasicInfo:_accountBasicInfo
            })
           }
        }
    }
}
export const accountRatePlanFieldChange=(selectedPlan,parentKey,childKey,value)=>{
    return (dispatch)=>{
        let _accountBasicInfo={}
        if( Object.keys((store.getState()).accountList).length>0){
            _accountBasicInfo= (store.getState()).accountList.accountBasicInfo;
           if(_accountBasicInfo){
            let _accRatePlanAndDiscountInfoByName=_accountBasicInfo.accRatePlanAndDiscountInfoByName;
            _accRatePlanAndDiscountInfoByName[selectedPlan][parentKey][childKey]=value;
            _accountBasicInfo.accRatePlanAndDiscountInfoByName=_accRatePlanAndDiscountInfoByName;
            dispatch({
                type: GET_ACCOUNT_REC,
                accountBasicInfo:JSON.parse(JSON.stringify(_accountBasicInfo)) 
            })
           }
        }
    }
}
export const accountRatePlanCustomFieldChange=(selectedPlan,parentKey,childKey,value,index,key)=>{
    return (dispatch)=>{
        let _accountBasicInfo={}
        if( Object.keys((store.getState()).accountList).length>0){
            _accountBasicInfo= (store.getState()).accountList.accountBasicInfo;
           if(_accountBasicInfo){
            let _accRatePlanAndDiscountInfoByName=_accountBasicInfo.accRatePlanAndDiscountInfoByName;
            _accRatePlanAndDiscountInfoByName[selectedPlan][parentKey][key][index][childKey]=value;
            _accountBasicInfo.accRatePlanAndDiscountInfoByName=_accRatePlanAndDiscountInfoByName;
            dispatch({
                type: GET_ACCOUNT_REC,
                accountBasicInfo:JSON.parse(JSON.stringify(_accountBasicInfo)) 
            })
           }
        }
    }
}
export const addCustomCharges=(selectedPlan,parentKey,childKey,value)=>{
    return (dispatch)=>{
        let _accountBasicInfo={}
        if( Object.keys((store.getState()).accountList).length>0){
            _accountBasicInfo= (store.getState()).accountList.accountBasicInfo;
           if(_accountBasicInfo){
            let _accRatePlanAndDiscountInfoByName=_accountBasicInfo.accRatePlanAndDiscountInfoByName;
            _accRatePlanAndDiscountInfoByName[selectedPlan][parentKey][childKey]=[..._accRatePlanAndDiscountInfoByName[selectedPlan][parentKey][childKey] || [],value];
            _accountBasicInfo.accRatePlanAndDiscountInfoByName=_accRatePlanAndDiscountInfoByName;
            dispatch({
                type: GET_ACCOUNT_REC,
                accountBasicInfo:JSON.parse(JSON.stringify(_accountBasicInfo)) 
            })
           }
        }
    }
}
export const removeCustomCharges=(selectedPlan,parentKey,childKey,index)=>{
    return (dispatch)=>{
        let _accountBasicInfo={}
        if( Object.keys((store.getState()).accountList).length>0){
            _accountBasicInfo= (store.getState()).accountList.accountBasicInfo;
           if(_accountBasicInfo){
            let _accRatePlanAndDiscountInfoByName=_accountBasicInfo.accRatePlanAndDiscountInfoByName;
            let array=[..._accRatePlanAndDiscountInfoByName[selectedPlan][parentKey][childKey]];
            if (index !== -1) {
                array.splice(index, 1);
            }
            _accRatePlanAndDiscountInfoByName[selectedPlan][parentKey][childKey]=array;
            _accountBasicInfo.accRatePlanAndDiscountInfoByName=_accRatePlanAndDiscountInfoByName;
            dispatch({
                type: GET_ACCOUNT_REC,
                accountBasicInfo:JSON.parse(JSON.stringify(_accountBasicInfo)) 
            })
           }
        }
    }
}
export const loadAccountDefaultRatePlan=(selectedPlan,parentKey,childKey,value)=>{
    return async(dispatch)=>{
        let _accountBasicInfo={}
        if( Object.keys((store.getState()).accountList).length>0){
            _accountBasicInfo= (store.getState()).accountList.accountBasicInfo;
           if(_accountBasicInfo){
            let _accRatePlanAndDiscountInfoByName=_accountBasicInfo.accRatePlanAndDiscountInfoByName;
            _accRatePlanAndDiscountInfoByName[selectedPlan][parentKey][childKey]=value;

            if((value||'')!==''){
                const _url= `rateplan/search/defaultRatePlanForEdit?ratePlanId=${value}&companyId=${getCompanyId()}&access_token=${getAccessToken()}`;
                try{
                    const resp=await getAxiosInstance(moduleTypes.ADMIN).get(_url)                
                    if(resp.status===200) { 
                        _accRatePlanAndDiscountInfoByName[selectedPlan]=initDefaultRatePlan(_accRatePlanAndDiscountInfoByName[selectedPlan],resp.data);                        
                        _accountBasicInfo.accRatePlanAndDiscountInfoByName=_accRatePlanAndDiscountInfoByName;
                        dispatch({
                            type: GET_ACCOUNT_REC,
                            accountBasicInfo:JSON.parse(JSON.stringify(_accountBasicInfo)) 
                        })                        
                    } else{}
                    //dispatch(spinnerActions.hideSpinner());
                }catch(error) {
                    //dispatch(spinnerActions.hideSpinner());
                    _accountBasicInfo.accRatePlanAndDiscountInfoByName=_accRatePlanAndDiscountInfoByName;
                    dispatch({
                        type: GET_ACCOUNT_REC,
                        accountBasicInfo:JSON.parse(JSON.stringify(_accountBasicInfo)) 
                    })                    
                }
            }else{
                _accountBasicInfo.accRatePlanAndDiscountInfoByName=_accRatePlanAndDiscountInfoByName;
                dispatch({
                    type: GET_ACCOUNT_REC,
                    accountBasicInfo:JSON.parse(JSON.stringify(_accountBasicInfo)) 
                })
            }   
        }
    }
    }
}
const initDefaultRatePlan=(accountRatePlanObject,defaultRatePlanObj)=>{
    const serviceRatePlanKeys={
        serviceCallRateDiscount:'serviceCallRateDiscount',
        serviceCallRate:'serviceCallRate'
    }
    accountRatePlanObject[serviceRatePlanKeys.serviceCallRate].serviceFlatRate=defaultRatePlanObj.serviceFlatRate||0.00
    accountRatePlanObject[serviceRatePlanKeys.serviceCallRate].serviceMinHours=defaultRatePlanObj.serviceMinHours||0
    accountRatePlanObject[serviceRatePlanKeys.serviceCallRate].serviceHourlyRate=defaultRatePlanObj.serviceHourlyRate||0.00
    accountRatePlanObject[serviceRatePlanKeys.serviceCallRate].serviceIncrementHours=defaultRatePlanObj.serviceIncrementHours||0
    accountRatePlanObject[serviceRatePlanKeys.serviceCallRate].serviceFreeHours=defaultRatePlanObj.serviceFreeHours||0
   // accountRatePlanObject[serviceRatePlanKeys.serviceCallRate].minServiceCharge=defaultRatePlanObj.minServiceCharge||0
    accountRatePlanObject[serviceRatePlanKeys.serviceCallRate].serviceStart=defaultRatePlanObj.serviceStart||''
    accountRatePlanObject[serviceRatePlanKeys.serviceCallRate].serviceEnd=defaultRatePlanObj.serviceEnd||''
    accountRatePlanObject[serviceRatePlanKeys.serviceCallRate].serviceMileageLoadLimit1Charge= defaultRatePlanObj.serviceMileageLoadLimit1Charge||0;
    accountRatePlanObject[serviceRatePlanKeys.serviceCallRate].serviceMileageLoadLimit2= defaultRatePlanObj.serviceMileageLoadLimit2||0;
    accountRatePlanObject[serviceRatePlanKeys.serviceCallRate].serviceMileageLoadLimit1= defaultRatePlanObj.serviceMileageLoadLimit1||0;
    accountRatePlanObject[serviceRatePlanKeys.serviceCallRate].serviceMileageLoadLimit2Charge= defaultRatePlanObj.serviceMileageLoadLimit2Charge||0;
    accountRatePlanObject[serviceRatePlanKeys.serviceCallRate].serviceMileageLoadChargeAfterLimit= defaultRatePlanObj.serviceMileageLoadChargeAfterLimit||0;
    accountRatePlanObject[serviceRatePlanKeys.serviceCallRate].serviceMileageUnloadLimit1= defaultRatePlanObj.serviceMileageUnloadLimit1||0;
    accountRatePlanObject[serviceRatePlanKeys.serviceCallRate].serviceMileageUnloadLimit1Charge= defaultRatePlanObj.serviceMileageUnloadLimit1Charge||0;
    accountRatePlanObject[serviceRatePlanKeys.serviceCallRate].serviceMileageUnloadLimit2= defaultRatePlanObj.serviceMileageUnloadLimit2||0;
    accountRatePlanObject[serviceRatePlanKeys.serviceCallRate].serviceMileageUnloadLimit2Charge= defaultRatePlanObj.serviceMileageUnloadLimit2Charge||0;
    accountRatePlanObject[serviceRatePlanKeys.serviceCallRate].serviceMileageUnloadChargeAfterLimit= defaultRatePlanObj.serviceMileageUnloadChargeAfterLimit||0;
    accountRatePlanObject[serviceRatePlanKeys.serviceCallRate].storagePeriod= defaultRatePlanObj.storagePeriod||'';
    accountRatePlanObject[serviceRatePlanKeys.serviceCallRate].storagePeriodId= defaultRatePlanObj.storagePeriodId||'';
    accountRatePlanObject[serviceRatePlanKeys.serviceCallRate].storageLimit1Charge=defaultRatePlanObj.storageLimit1Charge
    accountRatePlanObject[serviceRatePlanKeys.serviceCallRate].storageLimit1=defaultRatePlanObj.storageLimit1
    accountRatePlanObject[serviceRatePlanKeys.serviceCallRate].storageLimit2=defaultRatePlanObj.storageLimit2
    accountRatePlanObject[serviceRatePlanKeys.serviceCallRate].storageLimit2Charge=defaultRatePlanObj.storageLimit2Charge
    accountRatePlanObject[serviceRatePlanKeys.serviceCallRate].storageChargeAfterLimit=defaultRatePlanObj.storageChargeAfterLimit
    accountRatePlanObject[serviceRatePlanKeys.serviceCallRate].freeHours= defaultRatePlanObj.freeHours||0;
    accountRatePlanObject[serviceRatePlanKeys.serviceCallRate].threshold= defaultRatePlanObj.threshold||"00";
    accountRatePlanObject[serviceRatePlanKeys.serviceCallRate].storageCustomPeriod=defaultRatePlanObj.storageCustomPeriod||0;
    return accountRatePlanObject
}

export const accountRatePlanDiscountAllFieldChange=(selectedPlan,parentKey,chiledKeyArray=[],value)=>{
    return (dispatch)=>{
        let _accountBasicInfo={}
        if( Object.keys((store.getState()).accountList).length>0){
            _accountBasicInfo= (store.getState()).accountList.accountBasicInfo;
           if(_accountBasicInfo){
            let _accRatePlanAndDiscountInfoByName=_accountBasicInfo.accRatePlanAndDiscountInfoByName;
            chiledKeyArray.forEach((item)=>{
                _accRatePlanAndDiscountInfoByName[selectedPlan][parentKey][item]=(value===''?0.00:value);
            })            
            _accountBasicInfo.accRatePlanAndDiscountInfoByName=_accRatePlanAndDiscountInfoByName;
            dispatch({
                type: GET_ACCOUNT_REC,
                accountBasicInfo:Object.assign({},_accountBasicInfo) 
            })
           }
        }
    }
}

export const setSelectedAccountRatePlan=(ratePlan)=>{
    return (dispatch)=>{
        dispatch({
            type:SET_SELECTED_ACCOUNT_RATE_PLAN,
            selectedAccRatePlan:ratePlan
        })
    }
}

const getLatestRatePlan=(accountRatePlan)=>{
    let selectedRatePlan="";
    let ratePlanList=[];
      Object.keys(accountRatePlan).map((item)=>{
        ratePlanList.push({id:accountRatePlan[item].serviceCallRate.accountRatePlanId, plan:item});
      });
      if(ratePlanList.length>0){
        selectedRatePlan=(ratePlanList.sort((a,b)=>{return a.id-b.id}).reverse())[0].plan;
        if((selectedRatePlan||'')===''){
            return undefined
        }
      }else{
          return undefined
      }
      
    return selectedRatePlan
}

export const getAccountById=(accountId)=>{
    let _url=`account/select/accountBasicInfo?accountId=${accountId}&companyId=${getCompanyId()}&corporateId=1&access_token=${getAccessToken()}`
    return async(dispatch)=>{
        //dispatch(spinnerActions.showSpinner());
        try{
        const resp=await getAxiosInstance(moduleTypes.ADMIN).get(_url)       
            if(resp.status===200) {
                dispatch({
                    type: GET_ACCOUNT_REC,
                    accountBasicInfo:resp.data
                });
                dispatch(setSelectedAccountRatePlan(getLatestRatePlan(resp.data.accRatePlanAndDiscountInfoByName)))
            } else{
                    dispatch({
                        type: GET_ACCOUNT_REC,
                        accountBasicInfo:undefined
                    });
                    dispatch(setSelectedAccountRatePlan(getLatestRatePlan({})))
                }
            }
            //dispatch(spinnerActions.hideSpinner());
        catch(error) {
           // dispatch(spinnerActions.hideSpinner());
            dispatch({
                type: GET_ACCOUNT_REC,
                accountBasicInfo:undefined
            });
            //throw(error);
        }
    }
}

export const activeInactiveAccount=(id,status,cid)=>{
    const _url= `/account/enableOrDisable?isEnable=${status}&accountId=${id}&companyId=${getCompanyId()}&corporateId=1&access_token=${getAccessToken()}`;
    return async (dispatch) => {
        //dispatch(spinnerActions.showSpinner());
        try{
       const resp=await getAxiosInstance(moduleTypes.ADMIN).get(_url,undefined,getAccessToken())      
        if(resp.status===200) {   
            await dispatch(getAccountById(id));
            return {statusCode:200,statusMsg:resp.data.message} ;
        }else{
                return {statusCode:null,statusMsg:resp.data.message}
            }
        }
        //dispatch(spinnerActions.hideSpinner()); 
       catch(ex){ 
           //dispatch(spinnerActions.hideSpinner());
           return {statusCode:null,statusMsg:ex.message}
       }
    }
}
export const setBillAddressAsAccountAddress=()=>{
    return (dispatch)=>{
        let _accountBasicInfo=(store.getState()).accountList.accountBasicInfo;
        _accountBasicInfo.accountAddress.billingPhoneNo=_accountBasicInfo.accountAddress.corpPhoneNo
        _accountBasicInfo.accountAddress.billingExt=_accountBasicInfo.accountAddress.corpExt;
        _accountBasicInfo.accountAddress.billingFaxNo=_accountBasicInfo.accountAddress.corpFaxNo;
        _accountBasicInfo.accountAddress.billingAddressLine1=_accountBasicInfo.accountAddress.corpAddressLine1;
        _accountBasicInfo.accountAddress.billingEmail=_accountBasicInfo.accountAddress.corporateEmail;
        _accountBasicInfo.accountAddress.billingAddressLine2=_accountBasicInfo.accountAddress.corpAddressLine2
        _accountBasicInfo.accountAddress.billingCity=_accountBasicInfo.accountAddress.corpCity
        _accountBasicInfo.accountAddress.billingState=_accountBasicInfo.accountAddress.corpState
        _accountBasicInfo.accountAddress.billingZipCode=_accountBasicInfo.accountAddress.corpZipCode
        dispatch({
            type:GET_ACCOUNT_REC,
            accountBasicInfo: Object.assign({},_accountBasicInfo)
        })
    }
}

export const onSaveAccount=(accountObj,cid)=>{
    let _url=`account/insertAccountInfo?companyId=${getCompanyId()}&access_token=${getAccessToken()}`;
    let finalaccountObj=JSON.parse(JSON.stringify(accountObj));
    delete finalaccountObj.accRatePlanAndDiscountInfoByName[""];
    if(finalaccountObj.accRatePlanAndDiscountInfoByName['0'].serviceCallRate.accountRatePlanName===''){
        delete finalaccountObj.accRatePlanAndDiscountInfoByName["0"]
    }
    finalaccountObj.companyId=cid;
    finalaccountObj.corporateId=1;
    return async(dispatch)=>{
       // dispatch(spinnerActions.showSpinner());
       try{
            const resp=await getAxiosInstance(moduleTypes.ADMIN).post(_url,finalaccountObj);
            if(resp.data.statusCode===200){                  
                return {statusCode:200,statusMsg:resp.data.message};
            }else{
                return {statusCode:null,statusMsg:resp.data.message}
            }
        }catch(ex){
            return {statusCode:null,statusMsg:ex.message}                   
        }
    }
}