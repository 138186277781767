import * as actionTypes from "../../actions/actionTypes";
import {constStoragePeriod,constInStorageTime, constStoragePeriodId} from '../../util/constants';
const newRatePlan={
  "serviceCallRateDiscount": {
    "accountId": 0,
    "companyId": 0,
    "corporateId": 0,
    "ratePlanId": 0,
    "flatDiscountRate": 0,
    "all": 0,
    "towCharge": 0,
    "laborCharge": 0,
    "winchCharge": 0,
    "mileageCharge": 0,
    "storageCharge": 0,
    "miscelloneousCharge": 0,
    "firstUserCharge": 0,
    "secondUserCharge": 0,
    "thirdUserCharge": 0,
    "fourthUserCharge": 0
  },
  "serviceCallRate": {
    "accountRatePlanId": 0,
    "accountRatePlanCd": "new",
    "companyId": 0,
    "corporateId": 0,
    "accountId": 0,
    "ratePlanStartDate": null,
    "ratePlanEndDate": null,
    "accountRatePlanName": '',
    "towType": "",
    "minServiceCharge": 0,
    "serviceFlatRate": 0,
    "serviceHourlyRate": 0,
    "serviceMinHours": 0,
    "serviceIncrementHours": 0,
    "serviceFreeHours": 0,
    "serviceStart": null,
    "serviceEnd": null,
    "serviceMileageLoadLimit1": 0,
    "serviceMileageLoadLimit1Charge": 0,
    "serviceMileageLoadLimit2": 0,
    "serviceMileageLoadLimit2Charge": 0,
    "serviceMileageLoadChargeAfterLimit": 0,
    "serviceMileageUnloadLimit1": 0,
    "serviceMileageUnloadLimit1Charge": 0,
    "serviceMileageUnloadLimit2": 0,
    "serviceMileageUnloadLimit2Charge": 0,
    "serviceMileageUnloadChargeAfterLimit": 0,
    "storagePeriod": constStoragePeriodId[2],
    "storagePeriodId": 2,
    "storageLimit1": 0,
    "freeHours": 0,
    "threshold": "00",
    "storageLimit1Charge": 0,
    "storageLimit2": 0,
    "storageLimit2Charge": 0,
    "storageChargeAfterLimit": 0,
    "storageCustomPeriod": 0,
    "loadDefault": 0,
    "syncWithDefault": 0,
    "commissionType": 0,
    "storageStartTime": null,
    "storageEndTime": null,
    "customBehaviorId": 0,
    "maxStorage": '',
    "inStorageTime": constInStorageTime.dropped,
    "charges": [],
    "ratePlanAccountChargeMapping":[]
  },
  customBehaviours:[]  
}
const assignedComList=[]
const defaultAccount = {
  accountsDropDownList: [],
  accountInfoList: [],
  accountBillableChargeList:[],
  selectedAccRatePlan:'0',
  accountBasicInfo: {
    isAutoDispatch:1,
    accountBillingList:[{abid: 0,accountId: null,billableRate: 0,name:"",billableType: "",fromDate: "",toDate: ""}],
    accountName: "",
    accountType: "",
    billableRate:0,
    billableType:"",
    paymentType: "",
    creditLimit: 0,
    statementType: "",
    companyId: 0,
    corporateId: 0,
    accountId: 0,
    expiryDate: "",
    isActive: 1,
    isPORequired: 0,
    isPORequiredDropOff: 0,
    createdDate: "",
    company_priorty:1,
    availability_priorty:1,
    time_priorty:1,
    userId: "",
    accountingType: "",
    salesRepId: 0,
    accountPriority: 0,
    vendorId: "",
    contract: "",
    maxArrivalTime: 0,
    reviewLocation: "",
    subAccountMap:[],
    accountContactForm: {
      accountContacts: []
    },
    billingNotesForm: {
      billingNotes: []
    },
    billingContactForm: {
      accountContacts: []
    },
    accountNotesForm: {
      accountNotes: []
    },
    accountDefaultLocation: {
      otherInfo: {
        defCallerPhone: "",
        addedDate: "",
        defCallerName: "",
        defContactName: "",
        poNo: "",
        billTo: "0",
        defCallerEmail: "",
        defContactPhone: "",
        userId: ""
      },
      pickUpDetails: {
        zipCode: "",
        country: "",
        addr1: "",
        city: "",
        location: "",
        state: "",
        landmark: "",
        latLong:""
      },
      dropOffDetails: {
        zipCode: "",
        country: "",
        addr1: "",
        city: "",
        location: "",
        state: "",
        landmark: "",
        addrType: "",
        latLong:""
      }
    },
    accountHistories:[],
    accountReasons:[],
    accountChargeMapping:[],
    accRatePlanAndDiscountInfoByName: {      
      '0':JSON.parse(JSON.stringify(newRatePlan))
    },
    accountAddress: {
      companyId: 0,
      corporateId: 0,
      accountId: 0,
      corpAddressLine1: "",
      corpAddressLine2: "",
      corpCity: "",
      corpState: "",
      corpZipCode: "",
      corpPhoneNo: "",
      corpExt: "",
      corpFaxNo: "",
      corporateEmail: "",
      contract: "",
      vendorId: "",
      billingAddressLine1: "",
      billingAddressLine2: "",
      billingCity: "",
      billingState: "",
      billingZipCode: "",
      billingPhoneNo: "",
      billingExt: "",
      billingFaxNo: "",
      billingEmail: ""
    }
  },
};
const listOfAccountsLink = [];
export const accountReducer = (state = defaultAccount, action) => {
  switch (action.type) {
    case actionTypes.GET_ACCOUNTS_DROPDOWN:
    return{
      ...state,
      accountsDropDownList:
        action.payload || defaultAccount.accountsDropDownList
    }
    case actionTypes.GET_ASSIGN_COM_REC:
    return{
      ...state,
      assignedComList:
        action.assignedComList || assignedComList
    }
    case actionTypes.GET_ALL_ACCOUNTS:    
      return {
        ...state,
        accountInfoList:
          action.payload || defaultAccount.accountInfoList
      };    
    case actionTypes.GET_ACCOUNT_REC:
      return {
        ...state,
        accountBasicInfo:
         formatAccount(action.accountBasicInfo)
      };
      case actionTypes.SET_SELECTED_ACCOUNT_RATE_PLAN:
        return {...state,selectedAccRatePlan:(action.selectedAccRatePlan||'0')}
      case actionTypes.LIST_OF_ACCOUNTS_LINK:
          return {...state,listOfAccountsLink:(action.listOfAccountsLink||[])}
    default:
      return state;
  }
};                  
const formatAccount=(accountRec)=>{  
  let _accountInfo=JSON.parse(JSON.stringify(accountRec||defaultAccount.accountBasicInfo));
  _accountInfo.accRatePlanAndDiscountInfoByName=_accountInfo.accRatePlanAndDiscountInfoByName===null?[]:_accountInfo.accRatePlanAndDiscountInfoByName
  if(!_accountInfo.accRatePlanAndDiscountInfoByName["0"]){
    _accountInfo.accRatePlanAndDiscountInfoByName["0"]=JSON.parse(JSON.stringify(newRatePlan));
  }
  return _accountInfo;
}
