export const deployment_env='production'//'development'
export const BASE_AUTH_URL= (deployment_env==='production')?"https://driver-api.smartervms.com/svmservicesapi/":"https://devserviceapi.manageact.com/actservicesapi/";
export const BASE_URL=(deployment_env==='production')?"https://dispatcher-api.smartervms.com/management/":"https://devapi2.manageact.com/management/";
export const ADMIN_URL=(deployment_env==='production')?"https://admin-api.smartervms.com/svmsadminapi/":"https://devadminapi.manageact.com/actadmin/";
export const LAUNCH_LOGIN_URL=(deployment_env==='production')? "/login":
(process.env.NODE_ENV === 'production')?"/login":"/login";
export const DISPATCH_BASE_URL=(deployment_env==='production')?"https://dispatcher-api.smartervms.com/management/":"https://devapi2.manageact.com/management/";
export const REPORTS_URL=(deployment_env==='production')?"https://report-api.smartervms.com/svmsreportsapi/":"https://devreportsapi.manageact.com/actreports/";
//export const REPORT_BASE_URL=(deployment_env==='production')?"https://prodmobdashboardapi.allcityapp.com/actmobilereports/dashboard/":"https://devmobdashboardapi.allcityapp.com/actmobilereports/dashboard/"
export const REPORT_BASE_URL=(deployment_env==='production')?"https://dashboard-api.smartervms.com/svmsmobilereports/dashboard/":"https://devdashboardapi.manageact.com/actmobilereports/dashboard/"
export const GOOGLE_API_KEY=(deployment_env==='production')?"AIzaSyCPhSP57_v3UIrDN-9EAB8uXoa6m4pHhr0":"AIzaSyB95JaT-LA1RWVOaeBvuTU_Yf8eIsynnNc"
export const LAUNCH_STORE_NAME='persist:manage-root';
export const IS_LOCAL_STORAGE=true;
export const mapUrl = 'https://maps.googleapis.com/maps/api/geocode/json?address=';
export const googleMapApi=`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=geometry,drawing,places`
export const ROOT_DISPATCHER = 'dispatch';
export const LAUNCH_URL =window.location.origin;
export const ROOT_ADMIN = 'admin';
export const ROOT_REPORTS = 'reports';
export const GlobalValues={
    dateFormat:'MM/DD/YYYY',
    dispatcherDatefmt:'YYYY-MM-DD',
    timeFormat:'HH:mm:ss',
    currencySymbol: '$',
    dateTimeFormat:'MM/DD/YYYY HH:mm:ss',
    dateTimeFmtForDispatch:'YYYY-MM-DD HH:mm:ss'
}
export const moduleTypes={
    ADMIN:'A',
    DISPATCH:'D',
    SERVICE:'S',
    REPORTS:'R',
    MANAGE:'M',
    REPORT:'REPORT'
}
export const callStatus={
    'Available': {text:'Available',selected:false},
    'Assigned': {text:'Assigned',selected:false},
    'Unassigned': {text:'Unassigned',selected:false},
    'Requested': {text:'Requested',selected:false},
    'OnHold': {text:'On Hold',selected:false},
    'Accepted': {text:'Accepted',selected:false},
    'Enroute': {text:'Enroute',selected:false},
    'Arrived': {text:'Arrived',selected:false},
    'Hooked': {text:'Hooked',selected:false},
    'Dropped': {text:'Dropped',selected:false},
    'Cleared': {text:'Cleared',selected:false}
}
export const priorities={
  1:{text:'Priority 1',selected:false},
  2:{text:'Priority 2',selected:false},
  3:{text:'Priority 3',selected:false},
  4:{text:'Priority 4',selected:false},
  5:{text:'Priority 5',selected:false},
  6:{text:'Priority 6',selected:false},
  7:{text:'Priority 7',selected:false},
  8:{text:'Priority 8',selected:false}
}

export const mapfilterData={
    trucks:{
        'Available': {text:'Available',selected:false},
        'Assigned': {text:'Assigned',selected:false},
        'Loaded': {text:'Loaded',selected:false},
        'Doubled': {text:'Doubled',selected:false}
    },
    invoice:{
        pickup:{
            'Unassigned': {text:'Unassigned',selected:true},
            'Enroute': {text:'Enroute',selected:true},
            'Arrived': {text:'Arrived',selected:true},
            'Hooked': {text:'Hooked',selected:true},
            'Dropped': {text:'Dropped',selected:true}
        },
        priority:{
            1: {text:'1',selected:true},
            2: {text:'2',selected:true},
            3: {text:'3',selected:true},
            4: {text:'4',selected:true},
            5: {text:'5',selected:true},
            6: {text:'6',selected:true},
            7: {text:'7',selected:true},
            8: {text:'8',selected:true}

        }
    },
    dropLocation:true
}

export const statementType=[
    {value:'Commercial',text:'Commercial'},
    {value:'Government/Fleet',text:'City'},
    {value:'Miscellaneous',text:'Miscellaneous'},
    {value:'Police',text:'Police'}];
export const paymentType=[
    {value:'Cash',text:'Cash'},
    {value:'Credit',text:'Credit'},
    {value:'Cheque',text:'Cheque'},
    {value:'EmployeeAdvance',text:'Employee Advance'},
    {value:'Transfer',text:'Transfer'}
]
export const constServiceStart={
    'dispatch':'Dispatch',
    'enroute':'Enroute',
    'arrived':'Arrived'
}
export const constServiceEnd={
    'hooked':'Hooked',
    'dropped':'Dropped',
    'cleared':'Cleared'
}
export const constStoragePeriod={
    'calenderday':'Calendar',
    'hours24':'24_Hours',
    'custom':'Custom'
}
export const constStoragePeriodId={
    1:'Calendar',
    2:'24_Hours',
    3:'Custom'
}
export const constInStorageTime={
    'received':'Received',
    'dispatch':'Dispatch',
    'accepted':'Accepted',
    'enroute':'Enroute',
    'arrival':'Arrival',
    'hooked':'Hooked',
    'dropped':'Dropped',
    'clear':'Clear'
}
export const billableTypeManaged={
    PerTow:"PerTow", 
    Percentage:"Percentage"
}
export const billableTypeRetail={
    PerMonth:"PerMonth",
    PerUser:"PerUser"
}
export const appId="AQWS1234";

export const forgetOptions={
    FORGET_USER:'forgetUser',
    FORGET_PASS:'forgetPass',
    FORGET_BOTH:'forgetBoth'
}
export const  dropOffKeyValPair=[
    {"dropOffLocation":"location"},
    {"dropOffAddr_Address_Line1":"addr1"},
    {"dropOffAddr_Address_Landmark":"landmark"},
    {"dropOffAddr_Address_City":"city"},
    {"dropOffAddr_Address_State":"sortVal"},
    {"dropOffAddr_Address_Zip":"zipCode"},
    {"drop_Lat_Lang":"latLong"}
];

export const  pickUpkeyValPair=[
    {"pickUpLocation":"location"},
    {"pickUpAddr_Address_Line1":"addr1"},
    {"pickUpAddr_Address_Landmark":"landmark"},
    {"pickUpAddr_Address_City":"city"},
    {"pickUpAddr_Address_State":"sortVal"},
    {"pickUpAddr_Address_Zip":"zipCode"},
    {"pick_up_Lat_Lang":"latLong"}
];
export const  months = 
["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
